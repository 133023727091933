import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";
import PartyList from "../../components/ui/party-list";

import StockListRequest from "../../modals/stock/stock-list-request";

import { URLS } from "../../services/url";
import useHttp from "../../services/use-http";
import { LOCALSTORAGE } from "../../utils/constants";
import { ROUTES } from "../../utils/routes";
import { RUPEES_SYMBOL } from "../../utils/constants";
import {
  PartyWiseBillResponse,
  BillList,
} from "../../modals/party/party-wise-bill-response";
import BillListDescription from "../../modals/reports/bill-list-description";
import PartyWiseBillList from "./party-wise-bill-list";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const PartyWiseBill: React.FC<{}> = (props) => {
  const { t } = useTranslation();
  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();
  const shopId = localStorage.getItem(LOCALSTORAGE.shopId)!;

  const [errorMessage, setErrorMessage] = useState<string>(error);
  var [response, setResponse] = useState<PartyWiseBillResponse>();

  const [partyId, setPartyId] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    if (partyId !== "") {
      const request = new StockListRequest(shopId, partyId);
      //console.log("Request ", JSON.stringify(request));
      sendRequest(
        URLS.PARTY_WISE_BILL_LIST,
        "POST",
        JSON.stringify(request),
        "",
        "PARTY_WISE_BILL"
      );
    }
  }, [partyId]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "PARTY_WISE_BILL") {
      //response = data;

      //console.log("data ", data);

      if (data.code === 0) {
        setResponse(data);
        setErrorMessage("");
      } else {
        setResponse(undefined);
        setErrorMessage(data.message);
      }
    }
  }, [data, params, identifier, isLoading, error]);

  const onOptionChangeHandler = (partyId: string) => {
    setPartyId(partyId);
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-center">
        <h4>{t("party-wise-bill")}</h4>
        <ErrorModal
          show={errorMessage.length === 0 ? false : true}
          onClose={clear}
          message={errorMessage}
        />
      </div>
      <PartyList onOptionChange={onOptionChangeHandler}></PartyList>

      {isLoading && <LoadingIndicator />}
      {!isLoading && response && response.response && (
        <div className="list-group">
          <PartyWiseBillList data={response.response}></PartyWiseBillList>
        </div>
      )}
    </div>
  );
};

export default PartyWiseBill;
