import { NavLink } from "react-router-dom";
//import { NavLink } from "react-bootstrap";
import { ROUTES } from "../../utils/routes";
import logo from "../../images/logo_small.png";
import "../../css/main-nav.css";
import { LOCALSTORAGE } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageNavigation from "../ui/language-nav";
const Navbar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const logoutHandler = (e) => {
    e.preventDefault();
    localStorage.setItem(LOCALSTORAGE.isAuth, "0");
    navigate(ROUTES.LOGIN);
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid bg-secondary fixed-top">
        <ul className="navbar-nav ml-auto">
          <li
            className="nav-item"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
          >
            <NavLink to={ROUTES.HOME} className="avbar-brand">
              <div className="container">
                <div className="navbar-brand">
                  <img
                    src={logo}
                    alt="mBill Logo"
                    className="logo"
                    style={{ height: 50 }}
                  />
                </div>
              </div>
            </NavLink>
          </li>
        </ul>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse flex-grow-0 me-5"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto">
            <li
              className="nav-item"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
            >
              <NavLink
                to={ROUTES.HOME}
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                <h6>{t("home")}</h6>
              </NavLink>
            </li>

            <li
              className="nav-item"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
                to={ROUTES.REPORTS}
              >
                <h6>{t("reports")}</h6>
              </NavLink>
            </li>

            <li
              className="nav-item dropdown"
              //   data-bs-toggle="collapse"
              //   data-bs-target=".navbar-collapse.show"
            >
              <h6
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("stocks")}
              </h6>

              <ul className="dropdown-menu cursor-pointer ps-2">
                <li
                  className="nav-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    to={ROUTES.ADD_NEW_STOCK}
                    state={{ screenType: 2 }}
                    // to={{
                    //   pathname: ROUTES.ADD_NEW_STOCK,
                    //   state: { screenType: 2 },
                    // }}
                  >
                    <h6>{t("add-new-stock")}</h6>
                  </NavLink>
                </li>

                <li
                  className="nav-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    to={ROUTES.STOCK_LIST}
                  >
                    <h6>{t("stock-list")}</h6>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li
              className="nav-item dropdown"
              //   data-bs-toggle="collapse"
              //   data-bs-target=".navbar-collapse.show"
            >
              <h6
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("party")}
              </h6>

              <ul className="dropdown-menu cursor-pointer ps-2">
                <li
                  className="nav-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    to={ROUTES.NEW_PARTY}
                  >
                    <h6>{t("new-party")}</h6>
                  </NavLink>
                </li>

                <li
                  className="nav-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    to={ROUTES.NEW_BILL}
                  >
                    <h6>{t("new-bill")}</h6>
                  </NavLink>
                </li>

                <li
                  className="nav-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    to={ROUTES.PARTY_WISE_BILL}
                  >
                    <h6>{t("party-wise-bill")}</h6>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li
              className="nav-item"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
                to={ROUTES.ADD_EXPENSE}
              >
                <h6>{t("expense")}</h6>
              </NavLink>
            </li>

            <li
              className="nav-item dropdown"
              //   data-bs-toggle="collapse"
              //   data-bs-target=".navbar-collapse.show"
            >
              <h6
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("profile-details")}
              </h6>

              <ul className="dropdown-menu cursor-pointer ps-2">
                <li
                  className="nav-item"
                  data-bs-toggle="collapse"
                  data-bs-target=".navbar-collapse.show"
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    //to={ROUTES.NEW_PARTY}
                    onClick={logoutHandler}
                  >
                    <h6>{t("logout")}</h6>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
          <div className="mt-3 ms-3">
            <LanguageNavigation />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
