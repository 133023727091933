import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";
import PartyList from "../../components/ui/party-list";
import useForm from "../../hooks/useForm";
import PartyListRequest from "../../modals/party/party-list-request";
import { PartyListResponse } from "../../modals/party/party-list-response";
import AddNewStockRequest from "../../modals/stock/add-new-stock-request";
import { AddItemResponse } from "../../modals/stock/add-new-stock-response";
import { URLS } from "../../services/url";
import useHttp from "../../services/use-http";
import {
  LOCALSTORAGE,
  PAYMENT_MODE,
  EXPENSES_TYPE,
} from "../../utils/constants";
import { addExpenseForm } from "../../utils/formConfig";
import { ROUTES } from "../../utils/routes";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { Itemarraydetail } from "../../modals/reports/reports-response";
import { DATE_CURRENT, DATE_TIME_FORMAT } from "../../utils/date-time";
import AddExpenseRequest from "../../modals/expense/add-expense-request";
import { useTranslation } from "react-i18next";

const AddNewExpense: React.FC<{}> = (props) => {
  const { t } = useTranslation();
  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();
  const shopId = localStorage.getItem(LOCALSTORAGE.shopId)!;

  const { renderFormInputs, isFormValid, form } = useForm(addExpenseForm);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorTitle, setErrorTitle] = useState<string>("");

  const navigate = useNavigate();

  const [billDate, setBillDate] = useState<string>(DATE_CURRENT);

  const [paymentOption] = useState<string[]>(PAYMENT_MODE);
  const [paymentMode, setPaymentMode] = useState<string>(paymentOption[0]);

  const [expenseOption] = useState<string[]>(EXPENSES_TYPE);
  const [expenseMode, setExpenseMode] = useState<string>(expenseOption[0]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "ADD_NEW_EXPENSE") {
      //response = data;

      if (data.code === 0) {
        //setResponse(data.response);
        //console.log("data ", data);
        setErrorTitle("Success");
        setErrorMessage("Successfully Added Expenses");

        //console.log("Response ", data);
      } else {
        setErrorTitle("Error");
        setErrorMessage(data.message);
      }
    }
  }, [data, params, identifier, isLoading, error]);

  const submitApiCall = () => {
    if (!isFormValid()) return;

    const request = new AddExpenseRequest(
      shopId,
      billDate,
      expenseMode,
      paymentMode,
      form.description.value,
      form.amount.value
    );

    //console.log("Request ", JSON.stringify(request));
    sendRequest(
      URLS.ADD_NEW_EXPENSE,
      "POST",
      JSON.stringify(request),
      "",
      "ADD_NEW_EXPENSE"
    );
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isFormValid()) return;
    submitApiCall();
  };

  return (
    <div className="p-4">
      <div className="form-group" onSubmit={submitHandler}>
        <div className="d-flex justify-content-center">
          <h4>{t("add-new-expense")}</h4>
          <ErrorModal
            show={errorMessage.length === 0 ? false : true}
            onClose={() => {
              clear();

              if (data && data.code === 0) {
                navigate(ROUTES.REPORTS);
              }
            }}
            title={errorTitle}
            message={errorMessage}
          />
        </div>

        <form className="mb-3">
          <label htmlFor="date" className="form-label mt-2">
            {t("date")}
          </label>
          <input
            type="date"
            className="form-control"
            id="date"
            data-format={DATE_TIME_FORMAT}
            value={billDate}
            name="dateRequired"
            onChange={(e) => {
              setBillDate(e.target.value);
            }}
            max={DATE_CURRENT}
          />

          <label htmlFor="paymentMethod" className="form-label mt-3">
            {t("payment-method")}
          </label>
          <div className="form-group">
            <select
              id="paymentMethod"
              className="form-select"
              onChange={(e) => setPaymentMode(e.target.value)}
            >
              {paymentOption.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>

          <label htmlFor="expenseType" className="form-label mt-3">
            {t("expense-type")}
          </label>
          <div className="form-group">
            <select
              id="expenseType"
              className="form-select"
              onChange={(e) => setExpenseMode(e.target.value)}
            >
              {expenseOption.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </form>

        {renderFormInputs()}

        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <div className="row">
            <button
              type="button"
              className="btn btn-primary btn-md col-12 mt-3"
              disabled={!isFormValid()}
              onClick={submitHandler}
            >
              {t("submit")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddNewExpense;
