class CreateBillRequest {
  shopId: string;
  mobileNumber: string;
  custName: string;
  otherType: string;
  paymentMode: string;
  totalQuantity: string;
  totalPriceWithoutDis: string;
  discount: string;
  gstAmount: string;
  actualAmount: string;
  totalPriceWithDis: string;
  custBillDate: string;
  custBillTime: string;
  custBillMonth: string;
  deviceIMEI: string;
  details: Detail[];

  constructor(
    shopId: string,
    mobileNumber: string,
    custName: string,
    otherType: string,
    paymentMode: string,
    totalQuantity: string,
    totalPriceWithoutDis: string,
    discount: string,
    gstAmount: string,
    actualAmount: string,
    totalPriceWithDis: string,
    custBillDate: string,
    custBillTime: string,
    custBillMonth: string,
    deviceIMEI: string,
    details: Detail[]
  ) {
    this.shopId = shopId;
    this.mobileNumber = mobileNumber;
    this.custName = custName;
    this.otherType = otherType;
    this.paymentMode = paymentMode;
    this.totalQuantity = totalQuantity;
    this.totalPriceWithoutDis = totalPriceWithoutDis;
    this.discount = discount;
    this.gstAmount = gstAmount;
    this.actualAmount = actualAmount;
    this.totalPriceWithDis = totalPriceWithDis;
    this.custBillDate = custBillDate;
    this.custBillTime = custBillTime;
    this.custBillMonth = custBillMonth;
    this.deviceIMEI = deviceIMEI;
    this.details = details;
  }
}

export class Detail {
  itemId: string;
  itemName: string;
  itemSalePrice: string;
  purchasePrice: string;
  hsnCode: string;
  quantity: string;

  constructor(
    itemId: string,
    itemName: string,
    itemSalePrice: string,
    purchasePrice: string,
    hsnCode: string,
    quantity: string
  ) {
    this.itemId = itemId;
    this.itemName = itemName;
    this.itemSalePrice = itemSalePrice;
    this.purchasePrice = purchasePrice;
    this.hsnCode = hsnCode;
    this.quantity = quantity;
  }
}

export default CreateBillRequest;
