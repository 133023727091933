import React, { useState, useEffect, useRef } from "react";
import { URLS } from "../../services/url";

import Item from "../../components/ui/item";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import { SearchItemResponseData } from "../../modals/home/search-item-response";
import { CreateBillResponse } from "../../modals/home/create-bill-response";

import { useDispatch, useSelector } from "react-redux";

import { cartActions } from "../../store/cart-slice";
import useForm from "../../hooks/useForm";
import { discountForm } from "../../utils/formConfig";

import CreateBillRequest, {
  Detail,
} from "../../modals/home/create-bill-request";

import {
  DATE_CURRENT,
  TIME_CURRENT,
  DATE_TIME_FORMAT,
  ChangeDateTimeFormat,
} from "../../utils/date-time";
import useHttp from "../../services/use-http";
import { LOCALSTORAGE, PAYMENT_MODE } from "../../utils/constants";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";
import { RUPEES_SYMBOL } from "../../utils/constants";

import {
  CustomerBillList,
  Itemarraydetail,
} from "../../modals/reports/reports-response";
import { useTranslation } from "react-i18next";

const Home: React.FC<{}> = React.memo((props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { renderFormInputs, isFormValid, form } = useForm(discountForm);

  const mobileNumberRef = React.useRef<HTMLInputElement>(null);
  const customerNameRef = React.useRef<HTMLInputElement>(null);

  const cartItems = useSelector((state: any) => state.cart.items);
  const totalQuantity = useSelector((state: any) => state.cart.totalQuantity);
  const totalPrice = useSelector((state: any) => state.cart.totalPrice);

  const [discountMoney, setDiscountMoney] = useState<number>(0);
  const [gst, setGst] = useState<number>(0);
  const [priceAfterDiscount, setPriceAfterDiscount] = useState<number>(0);

  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();

  const [errorMessage, setErrorMessage] = useState<string>(error);
  var [response, setResponse] = useState<CreateBillResponse>();

  const [paymentOption] = useState<string[]>(PAYMENT_MODE);
  const [paymentMode, setPaymentMode] = useState<string>(paymentOption[0]);

  const [billDate, setBillDate] = useState<string>(DATE_CURRENT);
  const shopId = localStorage.getItem(LOCALSTORAGE.shopId)!;

  const [request, setRequest] = useState<CreateBillRequest>();

  //var requestModal : any;

  useEffect(() => {
    const discountValue = form.discount.value;

    if (discountValue < Number(totalPrice)) {
      if (isFormValid()) {
        setDiscountMoney(discountValue);

        const afterDiscountValue = Number(totalPrice) - discountValue;
        setPriceAfterDiscount(afterDiscountValue);

        if (afterDiscountValue > 1000) {
          setGst((afterDiscountValue * 12) / 100);
        } else {
          setGst((afterDiscountValue * 5) / 100);
        }
      } else {
        calculateNonDiscount();
      }
    } else {
      calculateNonDiscount();
    }
  }, [form, totalPrice, isFormValid]);

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function generateBillHandler(e: React.FormEvent): Promise<void> {
    e.preventDefault();

    const requestData = new CreateBillRequest(
      shopId,
      mobileNumberRef.current?.value + "",
      customerNameRef.current?.value + "",
      "",
      paymentMode,
      totalQuantity,
      totalPrice,
      form.discount.value,
      gst.toString(),
      totalPrice,
      priceAfterDiscount.toString(),
      ChangeDateTimeFormat(billDate),
      TIME_CURRENT,
      (new Date(billDate).getMonth() + 1).toString(),
      "web",
      cartItems
    );
    //await delay(1000);

    setRequest(requestData);

    sendRequest(
      URLS.CREATE_BILL,
      "POST",
      JSON.stringify(requestData),
      "",
      "CREATE_BILL"
    );

    //console.log("requestModal_1 ", JSON.stringify(request));
  }

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "CREATE_BILL") {
      //response = data;

      if (data.code === 0) {
        setResponse(data);

        //const billSerialNo = response?.response.billSerialNo;
        //console.log("response ", data);

        navigateToNextScreen(data);

        //const dataNew =   new CustomerBillList();

        // navigate(ROUTES.BILL_DETAILS, {
        //   state: {
        //     billSerialNo: data.response.billSerialNo,
        //     dataModal: request,
        //   },
        // });
      } else {
        setErrorMessage(data.message);
      }
    }
  }, [data, params, identifier, isLoading, error]);

  async function navigateToNextScreen(response: any): Promise<void> {
    const dataForNextScreen = await new CustomerBillList(
      request!.shopId,
      request!.shopId,
      request!.mobileNumber,
      request!.custName,
      request!.otherType,
      request!.paymentMode,
      request!.totalQuantity,
      request!.totalPriceWithoutDis,
      request!.discount,
      request!.gstAmount,
      request!.actualAmount,
      request!.totalPriceWithDis,
      request!.custBillDate,
      request!.custBillTime,
      request!.deviceIMEI,
      response?.response.billSerialNo,
      "",
      "",
      [cartItems]
    );
    dispatch(cartActions.clearCart());
    navigate(ROUTES.BILL_DETAILS, {
      state: {
        data: dataForNextScreen,
      },
    });
  }

  const calculateNonDiscount = () => {
    form.discount.value = "";
    setDiscountMoney(0);
    setPriceAfterDiscount(Number(totalPrice));

    if (Number(totalPrice) > 1000) {
      setGst((Number(totalPrice) * 12) / 100);
    } else {
      setGst((Number(totalPrice) * 5) / 100);
    }
  };

  const paymentOptionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentMode(e.target.value);
  };

  const onItemSearchHandler = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(ROUTES.ITEM_SEARCH);
  };

  const onAddMainItemHandler = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(ROUTES.ADD_NEW_STOCK, {
      state: {
        screenType: 1,
      },
    });
  };

  const onRemoveItemHandler = (barcodeId: string) => {
    // itemCtx.removeItem(barcodeId);
    dispatch(cartActions.removeItemFromCart(barcodeId));
  };

  const onAddItemHandler = (item: Itemarraydetail) => {
    //console.log("STATUS ", 0);
    //itemCtx.addItem(item);
    dispatch(cartActions.addItemToCart(item));
  };

  return (
    <div className="p-2">
      <div className="d-flex justify-content-center">
        <ErrorModal
          show={errorMessage.length === 0 ? false : true}
          onClose={clear}
          message={errorMessage}
        />
      </div>

      <section className="container-fluid">
        <form className="m-3">
          <input
            type="date"
            className="form-control"
            id="date"
            data-format={DATE_TIME_FORMAT}
            value={billDate}
            name="dateRequired"
            //defaultValue={billDate}
            onChange={(e) => {
              setBillDate(e.target.value);
            }}
            max={DATE_CURRENT}
          />

          <div className="input-group mt-3">
            <button
              type="button"
              className="btn btn-outline-primary col-6"
              onClick={onAddMainItemHandler}
            >
              {t("add-new-item")}
            </button>

            <button
              type="button"
              className="btn btn-outline-primary col-6"
              onClick={onItemSearchHandler}
            >
              {t("search-by-id")}
            </button>
          </div>

          {cartItems.length > 0 && (
            <>
              <ul className="list-group mt-3">
                {cartItems.map((item: Itemarraydetail, index: number) => {
                  return (
                    <Item
                      index={index}
                      item={item}
                      onRemoveItem={onRemoveItemHandler}
                      onAddHandler={onAddItemHandler}
                      key={item.id}
                    />
                  );
                })}
              </ul>

              <div className="mt-3">
                {renderFormInputs()}
                <div className="row mt-4 fw-bold">
                  <div className="col-6">{t("total-amount")}</div>

                  <div className="col-6 text-end">
                    {RUPEES_SYMBOL}
                    {totalPrice}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-6">{t("quantity")}</div>
                  <div className="col-6 text-end">{totalQuantity}</div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">{t("discount")}</div>
                  <div className="col-6 text-end">
                    {RUPEES_SYMBOL}
                    {discountMoney}
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">{t("cgst-sgst")}</div>
                  <div className="col-6 text-end">
                    {RUPEES_SYMBOL}
                    {gst}
                  </div>
                </div>
              </div>

              <div className="row mt-2 fw-bold">
                <div className="col-6">{t("total-price-include-tax")}</div>
                <div className="col-6 text-end">
                  {RUPEES_SYMBOL}
                  {priceAfterDiscount}
                </div>
              </div>

              <div className="row mb-3 mt-3">
                <label htmlFor="customerNamer" className="form-label col-4">
                  {t("payment-method")}
                </label>

                <form className="form-inline well">
                  <div className="form-group ">
                    <select
                      className="form-select"
                      onChange={(e) => paymentOptionHandler(e)}
                    >
                      {paymentOption.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </form>
              </div>
              <div className="mb-3">
                <label htmlFor="mobileNumber" className="form-label">
                  {t("mobile-number")}
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="mobileNumber"
                  placeholder={t("mobile-number")}
                  ref={mobileNumberRef}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="customerName" className="form-label">
                  {t("name")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="customerName"
                  ref={customerNameRef}
                  placeholder={t("name")}
                />
              </div>

              {isLoading && <LoadingIndicator />}
              {!isLoading && (
                <button
                  type="button"
                  className="btn btn-primary col-12 mt-3"
                  onClick={generateBillHandler}
                >
                  {t("generate-bill")}
                </button>
              )}
            </>
          )}
        </form>
      </section>
    </div>
  );
});

export default Home;
