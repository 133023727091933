/**
 * creates and returns a validation rule object that
 * is used by useForm hook to validate the form inputs
 *
 * @param {string} ruleName - name of the validation rule
 * @param {string} errorMessage - message to display
 * @param {function} validateFunc - validation function
 */
function createValidationRule(ruleName, errorMessage, validateFunc) {
  return {
    name: ruleName,
    message: errorMessage,
    validate: validateFunc,
  };
}
export function requiredRule(inputName) {
  return createValidationRule(
    "required",
    `${inputName} required`,
    (inputValue, formObj) => inputValue.length !== 0
  );
}

export function isEmailRule(inputName) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return createValidationRule(
    "isEmail",
    `${inputName} id invalid`,
    (inputValue, formObj) => regEmail.test(inputValue)
  );
}

export function isPasswordRule(inputName) {
  var regPassword = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  return createValidationRule(
    "isPassword",
    `${inputName} must be Capital, Small, Special Character and 8 character`,
    (inputValue, formObj) => regPassword.test(inputValue)
  );
}

export function isDiscountRule(inputName) {
  var regMobile = new RegExp("^(([1-9]{1}[0-9]{0,9}))$");

  return createValidationRule(
    "isDiscount",
    `${inputName} number invalid`,
    (inputValue, formObj) => regMobile.test(inputValue)
  );
}

export function isItemNameRule(inputName) {
  var regMobile = new RegExp("^(([0-9,A-Z,a-z,w ?]{4,20}))$");

  return createValidationRule(
    "isItemName",
    `${inputName} invalid`,
    (inputValue, formObj) => regMobile.test(inputValue)
  );
}

export function isNameRule(inputName) {
  var regMobile = new RegExp("^(([A-Z,a-z,w ?]{4,20}))$");

  return createValidationRule(
    "isItemName",
    `${inputName} invalid`,
    (inputValue, formObj) => regMobile.test(inputValue)
  );
}

export function isPriceRule(inputName) {
  var regMobile = new RegExp("^(([1-9]{1}[0-9]{0,9}))$");

  return createValidationRule(
    "isPrice",
    `${inputName} invalid`,
    (inputValue, formObj) => regMobile.test(inputValue)
  );
}

export function isMobileRule(inputName) {
  var regMobile = new RegExp("^(([1-9]{1}[0-9]{9}))$");

  return createValidationRule(
    "isMobile",
    `${inputName} number invalid`,
    (inputValue, formObj) => regMobile.test(inputValue)
  );
}

export function isPostalCodeRule(inputName) {
  var regMobile = new RegExp("^(([1-9]{1}[0-9]{5}))$");

  return createValidationRule(
    "isPostalCode",
    `${inputName} invalid`,
    (inputValue, formObj) => regMobile.test(inputValue)
  );
}

export function minLengthRule(inputName, minCharacters) {
  return createValidationRule(
    "minLength",
    `${inputName} should contain atleast ${minCharacters} characters`,
    (inputValue, formObj) => inputValue.length >= minCharacters
  );
}

export function maxLengthRule(inputName, maxCharacters) {
  return createValidationRule(
    "minLength",
    `${inputName} cannot contain more than ${maxCharacters} characters`,
    (inputValue, formObj) => inputValue.length <= maxCharacters
  );
}

export function passwordMatchRule() {
  return createValidationRule(
    "passwordMatch",
    `passwords do not match`,
    (inputValue, formObj) => inputValue === formObj.password.value
  );
}

export function isGstNoRule(inputName) {
  var regMobile = new RegExp("^(([A-Z,a-z,0-9]{15}))$");

  return createValidationRule(
    "isGstNo",
    `${inputName} invalid`,
    (inputValue, formObj) => regMobile.test(inputValue)
  );
}
