const date = new Date();
const futureDate = date.getDate();
date.setDate(futureDate);
export const DATE_CURRENT = date.toLocaleDateString("en-CA");
export const TIME_CURRENT = date.toLocaleTimeString("en-GB");

export function ChangeDateTimeFormat(date: string): string {
  return new Intl.DateTimeFormat("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(new Date(date));
}

export const DATE_TIME_FORMAT = "yyyy-MM-dd";
