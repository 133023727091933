import { Outlet, Navigate } from "react-router-dom";

import MainNav from "../../components/ui/main-nav";
import { ROUTES } from "../../utils/routes";
import { LOCALSTORAGE } from "../../utils/constants";
import Navbar from "../../components/ui/main-nav-new";

function RootLayout() {
  let isAuth = localStorage.getItem(LOCALSTORAGE.isAuth);
  return isAuth === "1" ? (
    <>
      {/* <MainNav /> */}
      <Navbar />
      <main className="mt-5">
        <Outlet />
      </main>
    </>
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  );
}

// import { Outlet, Navigate } from "react-router-dom";

// const PrivateRoutes = () => {
//   let auth = { token: false };
//   return auth.token ? <Outlet /> : <Navigate to="/login" />;
// };

// export default PrivateRoutes;

export default RootLayout;
