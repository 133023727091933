import { useEffect } from "react";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import I18nextBrowerLanguageDetector from "i18next-browser-languagedetector";

//import en from "./locales/en/translations.json";

i18n
  .use(Backend)
  .use(I18nextBrowerLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    //lng: "en",
    debug: true,
    resources: {
      en: {
        translations: require("./locales/en/translations.json"),
      },
      hi: {
        translations: require("./locales/hi/translations.json"),
      },
    },
    interpolation: {
      escapeValue: false,
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

//i18n.languages = ["en", "hi"];
i18n.languages = ["en", "hi"];
export const languagesList = [
  {
    name: "English",
    code: "en",
  },

  {
    name: "हिन्दी",
    code: "hi",
  },
];

export default i18n;

// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend";
// import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

// i18n
//   .use(Backend)
//   .use(I18nextBrowserLanguageDetector)
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     fallbackLng: "en",
//     debug: true,

//     interpolation: {
//       escapeValue: false, // react already safes from xss
//     },
//   });

// export default i18n;
