import React from "react";
import HintValue from "../../modals/billDetails/details-hint-value";

const BillDescription: React.FC<{
  index: number;
  item: HintValue;
}> = (props) => {
  return (
    <li
      // className="list-group-item"
      className={
        props.index % 2
          ? "list-group-item bg-list-primary"
          : "list-group-item bg-list-secondary"
      }
      key={props.item.hint}
    >
      <div className="row align-middle">
        <label className="col-6">{props.item.hint}</label>
        <label className="col-6 text-end">{props.item.value}</label>
      </div>
    </li>
  );
};

export default BillDescription;
