export enum LOCALSTORAGE {
  isAuth = "isAuth",
  shopId = "shopId",
}

export const RUPEES_SYMBOL = "₹ ";

export const PAYMENT_MODE = ["Cash", "Card", "UPI Pay", "Pending", "Other"];

export const EXPENSES_TYPE = [
  "Shop Rent",
  "Home Rent",
  "Salary",
  "Renovation",
  "Festival",
  "Medical",
  "Other",
];
