import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";
import PartyList from "../../components/ui/party-list";

import StockListRequest from "../../modals/stock/stock-list-request";

import { URLS } from "../../services/url";
import useHttp from "../../services/use-http";
import { LOCALSTORAGE } from "../../utils/constants";
import { ROUTES } from "../../utils/routes";
import { RUPEES_SYMBOL } from "../../utils/constants";
import {
  PartyWiseBillResponse,
  BillList,
} from "../../modals/party/party-wise-bill-response";
import useForm from "../../hooks/useForm";
import { partyBillForm } from "../../utils/formConfig";
import { DATE_CURRENT, DATE_TIME_FORMAT } from "../../utils/date-time";
import NewPartyBillRequest from "../../modals/party/new-party-bill-request";
import { NewPartyResponse } from "../../modals/party/new-party-response";
import { useTranslation } from "react-i18next";

const NewBill: React.FC<{}> = (props) => {
  const { t } = useTranslation();
  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();
  const shopId = localStorage.getItem(LOCALSTORAGE.shopId)!;

  const [errorMessage, setErrorMessage] = useState<string>(error);

  const [partyId, setPartyId] = useState<string>("");
  const [partyName, setPartyName] = useState<string>("");

  const [billStatusType, setBillStatusType] = useState<string[]>([
    "Due",
    "Paid",
  ]);

  const navigate = useNavigate();
  const { renderFormInputs, isFormValid, form } = useForm(partyBillForm);

  const [billDate, setBillDate] = useState<string>(DATE_CURRENT);
  const [billStatus, setBillStatus] = useState<string>("1");

  const addPartyHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (partyId !== "") {
      const request = new NewPartyBillRequest(
        shopId,
        partyName,
        partyId,
        form.billnumber.value,
        billDate,
        form.amount.value,
        form.gstamount.value,
        form.quantity.value,
        billStatus,
        billDate,
        billDate
      );

      sendRequest(
        URLS.NEW_PARTY_BILL,
        "POST",
        JSON.stringify(request),
        "",
        "ADD_NEW_PARTY"
      );
    }
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "ADD_NEW_PARTY") {
      //response = data;

      if (data.code === 0) {
        //console.log("data ", response);
        setErrorMessage("Bill Added Successfully");
      } else {
        setErrorMessage(data.message);
      }
    }
  }, [data, params, identifier, isLoading, error]);

  const onOptionChangeHandler = (partyId: string, partyName: string) => {
    setPartyId(partyId);
    setPartyName(partyName);
  };

  const billStatusOptionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    //props.onOptionChange(e.target.value);

    setBillStatus((Number(e.target.value) + 1).toString());
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-center">
        <h4>{t("new-bill")}</h4>
        <ErrorModal
          show={errorMessage.length === 0 ? false : true}
          onClose={() => {
            clear();

            if (data.code === 0) {
              navigate(0);
            }
          }}
          message={errorMessage}
        />
      </div>
      <PartyList onOptionChange={onOptionChangeHandler}></PartyList>

      {partyId && (
        <div>
          <label htmlFor="date">{t("date")}</label>
          <input
            type="date"
            className="form-control my-2"
            id="date"
            data-format={DATE_TIME_FORMAT}
            value={billDate}
            name="dateRequired"
            //defaultValue={billDate}
            onChange={(e) => {
              setBillDate(e.target.value);
            }}
            max={DATE_CURRENT}
          />
          {renderFormInputs()}

          <label className="mb-2" htmlFor="dropdown">
            {t("payment-status")}
          </label>
          <select
            id="dropdown"
            className="form-select"
            onChange={(e) => billStatusOptionHandler(e)}
          >
            {billStatusType.map((item, index: number) => {
              return (
                <option key={item} value={index}>
                  {item}
                </option>
              );
            })}
          </select>

          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <div className="row justify-content-center my-5 px-2">
              <button
                type="button"
                className="btn btn-primary btn-md col-12"
                disabled={!isFormValid()}
                onClick={addPartyHandler}
              >
                {t("submit")}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NewBill;
