class UpdatePartyBillRequest {
  id: number;
  paymentStatus: string;
  billPaidDate: string;

  constructor(id: number, paymentStatus: string, billPaidDate: string) {
    this.id = id;
    this.paymentStatus = paymentStatus;
    this.billPaidDate = billPaidDate;
  }
}

export default UpdatePartyBillRequest;
