class HintValue {
  hint: string;
  value: string;

  constructor(hint: string, value: string) {
    this.hint = hint;
    this.value = value;
  }
}

export default HintValue;
