import { t } from "i18next";
import { useTranslation } from "react-i18next";
import BillListDescriptionItem from "../../components/ui/bill-list-description-item";
import BillListItem from "../../components/ui/bill-list-item";
import BillListDescription from "../../modals/reports/bill-list-description";

import { CustomerBillList } from "../../modals/reports/reports-response";
import { PAYMENT_MODE } from "../../utils/constants";

const BillList: React.FC<{
  items: CustomerBillList[];
}> = (props) => {
  const { t } = useTranslation();
  var cashAmount: number = 0;
  var cardAmount: number = 0;
  var upiAmount: number = 0;
  var otherAmount: number = 0;
  var pendingAmount: number = 0;

  var cashQty: number = 0;
  var cardQty: number = 0;
  var upiQty: number = 0;
  var pendingQty: number = 0;
  var otherQty: number = 0;

  const totalAmount = props.items.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.totalPriceWithDis)),
    0
  );
  for (var data of props.items) {
    if (data.paymentMode === PAYMENT_MODE[0]) {
      cashAmount += Number(data.totalPriceWithDis);
      cashQty += 1;
    } else if (data.paymentMode === PAYMENT_MODE[1]) {
      cardAmount += Number(data.totalPriceWithDis);
      cardQty += 1;
    } else if (data.paymentMode === PAYMENT_MODE[2]) {
      upiAmount += Number(data.totalPriceWithDis);
      upiQty += 1;
    } else if (data.paymentMode === PAYMENT_MODE[3]) {
      pendingAmount += Number(data.totalPriceWithDis);
      pendingQty += 1;
    } else {
      otherAmount += Number(data.totalPriceWithDis);
      otherQty += 1;
    }
  }

  const descriptionList: BillListDescription[] = [
    new BillListDescription(
      "Total",
      props.items.length.toString(),
      totalAmount.toString()
    ),
    new BillListDescription(
      PAYMENT_MODE[0],
      cashQty.toString(),
      cashAmount.toString()
    ),
    new BillListDescription(
      PAYMENT_MODE[1],
      cardQty.toString(),
      cardAmount.toString()
    ),
    new BillListDescription(
      PAYMENT_MODE[2],
      upiQty.toString(),
      upiAmount.toString()
    ),
    new BillListDescription(
      PAYMENT_MODE[3],
      pendingQty.toString(),
      pendingAmount.toString()
    ),
    new BillListDescription(
      PAYMENT_MODE[4],
      otherQty.toString(),
      otherAmount.toString()
    ),
  ];

  return (
    <div>
      <div>
        <h6>{t("payment-description")}</h6>
        <div className="list-group">
          {descriptionList.map((item: BillListDescription, index: number) => {
            return (
              <BillListDescriptionItem
                index={index}
                item={item}
                key={item.title}
              />
            );
          })}
        </div>
      </div>
      <h6 className="mt-3">{t("bill-list")}</h6>
      <div className="list-group">
        {props.items.map((item: any, index: number) => {
          return <BillListItem index={index} item={item} key={item.id} />;
        })}
      </div>
    </div>
  );
};

export default BillList;
