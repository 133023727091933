import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";

import { URLS } from "../../services/url";
import useHttp from "../../services/use-http";
import { LOCALSTORAGE } from "../../utils/constants";

import useForm from "../../hooks/useForm";
import { newPartyForm } from "../../utils/formConfig";
import NewPartyRequest from "../../modals/party/new-party-request";
import { useTranslation } from "react-i18next";

const NewParty: React.FC<{}> = (props) => {
  const { t } = useTranslation();
  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();
  const shopId = localStorage.getItem(LOCALSTORAGE.shopId)!;

  const [errorMessage, setErrorMessage] = useState<string>(error);

  const navigate = useNavigate();
  const { renderFormInputs, isFormValid, form } = useForm(newPartyForm);

  const addPartyHandler = (e: React.FormEvent) => {
    e.preventDefault();

    const request = new NewPartyRequest(
      shopId,
      form.partyname.value,
      form.city.value,
      form.mobile.value,
      form.email.value,
      form.gst.value
    );

    sendRequest(
      URLS.NEW_PARTY,
      "POST",
      JSON.stringify(request),
      "",
      "ADD_NEW_PARTY"
    );
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "ADD_NEW_PARTY") {
      //response = data;

      if (data.code === 0) {
        //console.log("data ", response);
        setErrorMessage("Party Added Successfully");
      } else {
        setErrorMessage(data.message);
      }
    }
  }, [data, params, identifier, isLoading, error]);

  return (
    <div className="p-4">
      <div className="d-flex justify-content-center">
        <h4>{t("new-party")}</h4>
        <ErrorModal
          show={errorMessage.length === 0 ? false : true}
          onClose={() => {
            clear();

            if (data.code === 0) {
              navigate(0);
            }
          }}
          message={errorMessage}
        />
      </div>

      <div>
        {renderFormInputs()}
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <div className="row justify-content-center my-5 px-2">
            <button
              type="button"
              className="btn btn-primary btn-md col-12"
              disabled={!isFormValid()}
              onClick={addPartyHandler}
            >
              {t("submit")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewParty;
