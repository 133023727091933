class AddNewStockRequest {
  shopId: string;
  partyId: string;
  itemName: string;
  itemPurchasePrice: string;
  itemSalesPrice: string;
  percentageDiscount: string;
  quantity: string;
  hsnCode: string;

  constructor(
    shopId: string,
    partyId: string,
    itemName: string,
    itemPurchasePrice: string,
    itemSalesPrice: string,
    percentageDiscount: string,
    quantity: string,
    hsnCode: string
  ) {
    this.shopId = shopId;
    this.partyId = partyId;
    this.itemName = itemName;
    this.itemPurchasePrice = itemPurchasePrice;
    this.itemSalesPrice = itemSalesPrice;
    this.percentageDiscount = percentageDiscount;
    this.quantity = quantity;
    this.hsnCode = hsnCode;
  }
}

export default AddNewStockRequest;
