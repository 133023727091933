class SearchItemRequest {
  shopId: string;
  barcodeId: string;

  constructor(shopId: string, barcodeId: string) {
    this.shopId = shopId;
    this.barcodeId = barcodeId;
  }
}

export default SearchItemRequest;
