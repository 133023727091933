import { Suspense, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";

import RootLayout from "./pages/root/root-layout";
import ErrorPage from "./components/ui/error-route";
import Login from "./pages/login/login";

import Home from "./pages/home/home";
import Reports from "./pages/repots/reports";
import ItemSearch from "./pages/home/item-search";
import BillDetails from "./pages/billDetails/bill-details";

import { ROUTES } from "./utils/routes";
import "./css/App.css";
import AddNewStock from "./pages/stock/add-new-stock";
import PrintLabel from "./pages/printLabel/print-label";
import StockList from "./pages/stock/stock-list";
import NewParty from "./pages/party/new-party";
import NewBill from "./pages/party/new-bill";
import PartyWiseBill from "./pages/party/party-wise-bill";
import PartyBillDetails from "./pages/party/party-bill-details";
import ErrorPageNew from "./components/ui/error-route-new";
import SignUp from "./pages/signup/sign-up";
import ComingSoon from "./pages/coming-soon.tsx/coming-soon";
import AddNewExpense from "./pages/expenses/add-expense";
import i18n from "./i18n/config";
import { ThemeProvider } from "react-bootstrap";

import LoadingIndicator from "./components/ui/loading-indicator";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path={ROUTES.MAIN}
      errorElement={<ErrorPageNew />}
      //element={<Login />}
    >
      <Route path={ROUTES.MAIN} index={true} element={<Login />}></Route>
      {/* <Route path={ROUTES.MAIN} element={<Login />} /> */}

      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
      <Route path={ROUTES.COMING_SOON} element={<ComingSoon />} />

      <Route element={<RootLayout />}>
        <Route path={ROUTES.HOME} element={<Home />}></Route>
        <Route path={ROUTES.REPORTS} element={<Reports />} />

        <Route path={ROUTES.ITEM_SEARCH} element={<ItemSearch />} />
        <Route path={ROUTES.ADD_NEW_STOCK} element={<AddNewStock />} />
        <Route
          path={ROUTES.BILL_DETAILS}
          element={<BillDetails />}
          action={({ params }) => {}}
        />
        <Route
          path={ROUTES.PRINT_LABEL}
          element={<PrintLabel />}
          action={({ params }) => {}}
        />
        <Route
          path={ROUTES.STOCK_LIST}
          element={<StockList />}
          action={({ params }) => {}}
        />
        <Route path={ROUTES.NEW_PARTY} element={<NewParty />} />
        <Route path={ROUTES.NEW_BILL} element={<NewBill />} />
        <Route path={ROUTES.PARTY_WISE_BILL} element={<PartyWiseBill />} />
        <Route
          path={ROUTES.PARTY_BILL_DETAILS}
          element={<PartyBillDetails />}
        />
        <Route
          path={ROUTES.PARTY_BILL_DETAILS}
          element={<PartyBillDetails />}
        />
        <Route path={ROUTES.ADD_EXPENSE} element={<AddNewExpense />} />
      </Route>
    </Route>
  )
);

function App() {
  //const [locale, setLocale] = useState(i18n.language);
  //i18n.on("languageChanged", (lng) => setLocale(i18n.language));

  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
