import { useState } from "react";
import { useTranslation } from "react-i18next";
import BillListDescriptionItem from "../../components/ui/bill-list-description-item";
import BillListItem from "../../components/ui/bill-list-item";
import PurchaseListItem from "../../components/ui/purchase-list-item";
import BillListDescription from "../../modals/reports/bill-list-description";

import { purchaseBillList } from "../../modals/reports/reports-response";
import { PAYMENT_MODE } from "../../utils/constants";

const PurchaseList: React.FC<{
  items: purchaseBillList[];
}> = (props) => {
  const { t } = useTranslation();
  var dueAmount: number = 0;
  var paidAmount: number = 0;

  var dueQty: number = 0;
  var paidQty: number = 0;

  const totalAmount = props.items.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.totalBillAmount)),
    0
  );
  for (var data of props.items) {
    const paymentStatus = Number(data.paymentStatus);
    if (paymentStatus === 1) {
      //Due
      dueAmount += Number(data.totalBillAmount);
      dueQty += 1;
    } else if (paymentStatus === 2) {
      //paidAmount += Number(data.totalBillAmount);
      paidAmount += Number(data.totalBillAmount);
      paidQty += 1;
    }
  }

  const descriptionList: BillListDescription[] = [
    new BillListDescription(
      t("total-amount"),
      props.items.length.toString(),
      totalAmount.toString()
    ),
    new BillListDescription(t("due"), dueQty.toString(), dueAmount.toString()),
    new BillListDescription(
      t("paid"),
      paidQty.toString(),
      paidAmount.toString()
    ),
  ];

  //console.log("items ", props.items);

  return (
    <div>
      <div>
        <h6>{t("payment-description")}</h6>

        <div className="list-group">
          {descriptionList.map((item: BillListDescription, index: number) => {
            return (
              <BillListDescriptionItem
                index={index}
                item={item}
                key={item.title}
              />
            );
          })}
        </div>
      </div>
      <h6 className="mt-3">{t("list")}</h6>
      <div className="list-group">
        {props.items.map((item: any, index: number) => {
          return <PurchaseListItem index={index} item={item} key={item.id} />;
        })}
      </div>
    </div>
  );
};

export default PurchaseList;
