import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    totalQuantity: 0,
    totalPrice: 0,
    changed: false,
  },

  reducers: {
    clearCart(state) {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      state.changed = false;
    },

    addItemToCart(state, action) {
      const newItem = action.payload;
      const existingItem = state.items.find((item) => item.id === newItem.id);

      //state.totalQuantity++;

      if (!existingItem) {
        state.items.push(newItem);
      } else {
        existingItem.quantity++;

        existingItem.totalPrice = (
          parseFloat(existingItem.itemSalePrice) *
          parseFloat(existingItem.quantity)
        ).toString();

        // console.log("totalPrice", existingItem.totalPrice);
      }

      state.totalQuantity = state.items
        .reduce(
          (total, currentValue) =>
            (total = total + parseFloat(currentValue.quantity)),
          0
        )
        .toString();

      state.totalPrice = state.items
        .reduce(
          (total, currentValue) =>
            (total = total + parseFloat(currentValue.totalPrice)),
          0
        )
        .toString();

      state.changed = true;
    },
    removeItemFromCart(state, action) {
      const id = action.payload;
      const existingItem = state.items.find((item) => item.id === id);
      state.totalQuantity--;

      //console.log("Quantity ", existingItem.quantity);

      if (Number(existingItem.quantity) === 1) {
        //console.log("Quantity_1");
        state.items = state.items.filter((item) => item.id !== id);
      } else {
        //console.log("Quantity_2");
        existingItem.quantity--;
        //existingItem.totalPrice = existingItem.totalPrice - existingItem.price;

        existingItem.totalPrice = (
          parseFloat(existingItem.itemSalePrice) *
          parseFloat(existingItem.quantity)
        ).toString();
      }

      state.totalQuantity = state.items
        .reduce(
          (total, currentValue) =>
            (total = total + parseFloat(currentValue.quantity)),
          0
        )
        .toString();
      state.totalPrice = state.items
        .reduce(
          (total, currentValue) =>
            (total = total + parseFloat(currentValue.totalPrice)),
          0
        )
        .toString();

      state.changed = true;
    },
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice;
