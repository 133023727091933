class DeleteBillRequest {
  id: number;
  totalPriceWithDis: string;

  constructor(id: number, totalPriceWithDis: string) {
    this.id = id;
    this.totalPriceWithDis = totalPriceWithDis;
  }
}

export default DeleteBillRequest;
