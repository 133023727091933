import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";
import PartyListRequest from "../../modals/party/party-list-request";
import { PartyListResponse } from "../../modals/party/party-list-response";
import { URLS } from "../../services/url";
import useHttp from "../../services/use-http";
import { LOCALSTORAGE } from "../../utils/constants";

const PartyList: React.FC<{
  onOptionChange: (id: string, value: string) => void;
}> = (props) => {
  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();
  const shopId = localStorage.getItem(LOCALSTORAGE.shopId)!;

  const [errorMessage, setErrorMessage] = useState<string>(error);
  var [response, setResponse] = useState<PartyListResponse>();

  const { t } = useTranslation();

  useEffect(() => {
    const request = new PartyListRequest(shopId);
    sendRequest(
      URLS.PARTY_LIST,
      "POST",
      JSON.stringify(request),
      "",
      "PARTY_LIST"
    );
  }, []);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "PARTY_LIST") {
      //response = data;

      if (data.code === 0) {
        setResponse(data);
        //console.log("Reports ", data);
        if (data.response.length > 0) {
          setErrorMessage("");

          props.onOptionChange(
            data.response[0].partyId,
            data.response[0].partyName
          );
        } else {
          setErrorMessage("No more party available. Please add party first");
        }
      } else {
        setResponse(undefined);
        setErrorMessage(data.message);
      }
    }
  }, [data, params, identifier, isLoading, error]);

  const partyOptionHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    props.onOptionChange(
      e.target.value,
      response!.response[e.target.selectedIndex].partyName.toString()
    );
  };

  return (
    <div className="pb-3">
      <form className="form-inline well">
        <div className="form-group ">
          <div className="d-flex justify-content-center">
            <ErrorModal
              show={errorMessage.length === 0 ? false : true}
              onClose={clear}
              message={errorMessage}
            />
          </div>

          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              <label className="mb-2" htmlFor="dropdown">
                {t("party-name")}
              </label>
              <select
                id="dropdown"
                className="form-select"
                onChange={(e) => partyOptionHandler(e)}
              >
                {response?.response.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.partyName}
                    </option>
                  );
                })}
              </select>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default PartyList;
