export enum URLS {
  //MAIN = "https://mbill.co.in/mbill/API/services/",
  LOGIN = "login",
  REGISTER = "shopregistration",
  SEARCH_ITEM = "searchItem",
  REPORTS = "reportsFromToDate",
  CREATE_BILL = "createCustomerBill",
  PARTY_LIST = "partyList",
  ADD_NEW_STOCK = "savestock",
  STOCK_LIST = "stockPartyList",
  PARTY_WISE_BILL_LIST = "partybillllistaccparty",
  DELETE_PARTY_BILL = "holeSaleBillDeleteUpdateStatus",
  UPDATE_PARTY_BILL = "holeSaleBillUpdateStatus",
  DELETE_BILL = "createBillUpdateStatus",
  NEW_PARTY_BILL = "saveholesalebill",
  NEW_PARTY = "saveparty",
  ADD_NEW_EXPENSE = "savesexpenses",
}
