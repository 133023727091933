import { useState } from "react";
import { useTranslation } from "react-i18next";
import BillDescription from "../../components/ui/bill-description";
import BillListDescriptionItem from "../../components/ui/bill-list-description-item";
import HintValue from "../../modals/billDetails/details-hint-value";
import BillListDescription from "../../modals/reports/bill-list-description";

import ReportsResponse, {
  CustomerBillList,
} from "../../modals/reports/reports-response";
import { PAYMENT_MODE, RUPEES_SYMBOL } from "../../utils/constants";

const Summary: React.FC<{
  data: ReportsResponse;
}> = (props) => {
  const { t } = useTranslation();

  const saleTotalAmount = props.data.customerBillList.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.totalPriceWithDis)),
    0
  );

  const purchaseTotalAmount = props.data.purchaseBillList.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.totalBillAmount)),
    0
  );

  const expensesTotalAmount = props.data.expensesList.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.expAmount)),
    0
  );

  const [list] = useState<HintValue[]>([
    new HintValue(
      t("total-sale-amount"),
      RUPEES_SYMBOL + saleTotalAmount.toString()
    ),
    new HintValue(
      t("total-purchase-amount"),
      RUPEES_SYMBOL + purchaseTotalAmount.toString()
    ),
    new HintValue(
      t("total-expenses-amount"),
      RUPEES_SYMBOL + expensesTotalAmount.toString()
    ),
    new HintValue(
      t("closing-amount"),
      RUPEES_SYMBOL +
        (saleTotalAmount - (purchaseTotalAmount + expensesTotalAmount))
    ),
  ]);

  return (
    <div>
      <div className="list-group">
        {list.map((item: HintValue, index: number) => {
          return <BillDescription index={index} item={item} key={item.hint} />;
        })}
      </div>
    </div>
  );
};

export default Summary;
