class SignUpRequest {
  shopName: string;
  ownerName: string;
  ownerFatherName: string;
  mobileNumber: string;
  landlineNumber: string;
  addressLine1: string;
  addressLine2: string;
  areaName: string;
  dictName: string;
  postalCode: string;
  cityName: string;
  stateName: string;
  countryName: string;
  email: string;
  password: string;
  gstno: string;
  payment_status: string;
  payment_date: string;

  constructor(
    shopName: string,
    ownerName: string,
    ownerFatherName: string,
    mobileNumber: string,
    landlineNumber: string,
    addressLine1: string,
    addressLine2: string,
    areaName: string,
    dictName: string,
    postalCode: string,
    cityName: string,
    stateName: string,
    countryName: string,
    password: string,
    email: string,
    gstno: string,
    payment_status: string,
    payment_date: string
  ) {
    this.shopName = shopName;
    this.ownerName = ownerName;
    this.ownerFatherName = ownerFatherName;
    this.mobileNumber = mobileNumber;
    this.landlineNumber = landlineNumber;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.areaName = areaName;
    this.dictName = dictName;
    this.postalCode = postalCode;
    this.cityName = cityName;
    this.stateName = stateName;
    this.countryName = countryName;
    this.password = password;
    this.email = email;
    this.gstno = gstno;
    this.payment_status = payment_status;
    this.payment_date = payment_date;
  }
}

export default SignUpRequest;
