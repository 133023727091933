import { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n, { languagesList } from "../../i18n/config";
import { LOCALSTORAGE, PAYMENT_MODE } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";

function LanguageNavigation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [locale, setLocale] = useState();

  const [languageOption] = useState(languagesList);

  useEffect(() => {
    //console.log("languagesList ", languagesList);

    const selLanguageCode = i18n.language;
    //console.log("selLanguageCode", selLanguageCode);
    if (languagesList.length > 0) {
      try {
        const codeValue = languagesList.filter((item) => {
          return item.code === selLanguageCode;
        })[0].code;

        //console.log("codeValue ", codeValue);

        setLocale(codeValue);
      } catch (e) {
        //console.log("error ", e);
        setLocale("en");
      }
    } else {
      setLocale("en");
    }
  }, []);

  const languageOptionHandler = (e) => {
    const selLanguageCode = e.target.value;
    //setPaymentMode(e.target.value);
    //i18n.changeLanguage(l);

    i18n.changeLanguage(selLanguageCode);

    setLocale(
      languagesList.filter((item) => {
        return item.code === selLanguageCode;
      })[0].code
    );

    navigate(0);

    //console.log(locale);
  };

  return (
    <div className="row mb-3 d-flex flex-row-reverse">
      <form className="form-inline well col-auto">
        <div className="form-group">
          <select
            className="form-select bg-transparent"
            onChange={(e) => languageOptionHandler(e)}
            value={locale}
          >
            {languageOption.map((item) => {
              return (
                <option key={item.name} value={item.code}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      </form>
      {/* <label htmlFor="customerNamer" className="form-label col-auto">
        Language
      </label> */}
    </div>
  );
}

export default LanguageNavigation;
