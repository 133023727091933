class ReportsRequest {
  shopId: string;
  FromDate: string;
  ToDate: string;

  constructor(shopId: string, FromDate: string, ToDate: string) {
    this.shopId = shopId;
    this.FromDate = FromDate;
    this.ToDate = ToDate;
  }
}

export default ReportsRequest;
