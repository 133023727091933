class AddExpenseRequest {
  shopId: string;
  expDate: string;
  expType: string;
  expAmountType: string;
  expOther: string;
  expAmount: string;

  constructor(
    shopId: string,
    expDate: string,
    expType: string,
    expAmountType: string,
    expOther: string,
    expAmount: string
  ) {
    this.shopId = shopId;
    this.expDate = expDate;
    this.expType = expType;
    this.expAmountType = expAmountType;
    this.expOther = expOther;
    this.expAmount = expAmount;
  }
}

export default AddExpenseRequest;
