import { useEffect, useState } from "react";
//import { useTranslation } from "react-i18next";

import "../../css/login.css";

import slide2_img from "../../images/slide_2.png";

import slide4_img from "../../images/playstore.png";
import slide5_img from "../../images/appstore.png";

import logo from "../../images/logo_small.png";

import useForm from "../../hooks/useForm";
import { signInForm } from "../../utils/formConfig";
import useHttp from "../../services/use-http";

import LoginRequest from "../../modals/login/login-request";
import ErrorModal from "../../components/ui/error-modal";
import { URLS } from "../../services/url";
import LoadingIndicator from "../../components/ui/loading-indicator";

import { NavLink, useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import { LOCALSTORAGE } from "../../utils/constants";
import SlidingContentRequest from "../../modals/login/sliding-content-request";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/config";
import LanguageNavigation from "../../components/ui/language-nav";

const Login: React.FC = () => {
  const { t } = useTranslation();
  //const x = (key: string) => (t(key) as string) || undefined;

  const navigate = useNavigate();

  //console.log({t('login')});

  const { renderFormInputs, isFormValid, form } = useForm(signInForm);

  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();

  const [errorMessage, setErrorMessage] = useState<string>(error);

  const [slidingContent, setSlidingContent] = useState<SlidingContentRequest[]>(
    [
      new SlidingContentRequest(
        1,
        slide2_img,
        t("what-we-provide"),
        t("what-we-provide-value"),
        ""
      ),
      new SlidingContentRequest(
        2,
        slide4_img,
        t("download-android-app-now"),
        t("what-we-provide-value"),
        "https://play.google.com/store/apps/details?id=com.mbill"
      ),
      new SlidingContentRequest(
        3,
        slide5_img,
        t("ios-app-coming-soon"),
        t("what-we-provide-value"),
        ""
      ),
    ]
  );

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "LOGIN") {
      //console.log("loginResponse ", data);
      if (data.code === 0) {
        setErrorMessage("");

        localStorage.setItem(LOCALSTORAGE.isAuth, "1");
        localStorage.setItem(
          LOCALSTORAGE.shopId,
          data.response.loginDetails.id
        );

        navigate(ROUTES.HOME);
      } else {
        setErrorMessage(data.message);
      }
    } else if (!isLoading && identifier === "REGISTER") {
      setErrorMessage("");
    }
  }, [data, params, identifier, isLoading, error]);

  const loginApiCall = () => {
    if (!isFormValid()) return;

    const request = JSON.stringify(
      new LoginRequest(form.mobile.value.trim(), form.password.value.trim())
    );

    sendRequest(URLS.LOGIN, "POST", request, "", "LOGIN");
  };

  const loginHandler = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isFormValid()) return;

    loginApiCall();
  };

  return (
    <div id="mlogin_screen" className="container-fluid d-flex">
      <ErrorModal
        show={errorMessage.length === 0 ? false : true}
        onClose={clear}
        message={errorMessage}
      />
      <div className="row flex-fill">
        <div className="col-12 col-md-6 my-logo-bg card border-0 justify-content-center bg-secondary">
          <div
            id="carouselExampleCaptions"
            className="carousel carousel-dark slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner mt-3">
              {slidingContent.map((sliding: SlidingContentRequest) => {
                return (
                  <div
                    key={sliding.id}
                    //className="carousel-item text-center"
                    className={
                      sliding.id === 1
                        ? "carousel-item active text-center"
                        : "carousel-item text-center"
                    }
                  >
                    <a href={sliding.link} target={sliding.link && "_blank"}>
                      <img
                        src={sliding.src}
                        className="img-fluid"
                        alt={sliding.title}
                        style={{ height: 150 }}
                      />
                    </a>

                    <h5 className="mt-5">{sliding.title}</h5>
                    <p className="mt-3">{sliding.message}</p>
                  </div>
                );
              })}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div
          className="col-12 col-md-6  my-form-bg card  border-0 justify-content-center mt-2"
          onSubmit={loginHandler}
        >
          <LanguageNavigation />
          <img src={logo} className="rounded mx-auto d-block" alt="logo img" />

          {renderFormInputs()}

          <div className="my-1">
            <NavLink to={ROUTES.COMING_SOON}> {t("forgot-password")}</NavLink>
          </div>

          {isLoading && <LoadingIndicator />}
          {!isLoading && (
            <button
              type="submit"
              className="btn btn-primary btn-md mt-3 mb-4"
              disabled={!isFormValid()}
              onClick={loginHandler}
            >
              {t("login")}
            </button>
          )}

          <p className="text-center">
            {t("dont-have-account")}
            <NavLink to={ROUTES.SIGN_UP}> {t("sign-up")}</NavLink>
          </p>

          {/* <p className="text-center mt-3">{process.env.REACT_APP_VERSION}</p> */}
          <div
            className="text-center my-3"
            // style="background-color: rgba(0, 0, 0, 0.2);"
          >
            <p className="text-dark">
              {process.env.REACT_APP_VERSION} © 2022 Copyright mBill
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
