class StockListRequest {
  shopId: string;
  partyId: string;

  constructor(shopId: string, partyId: string) {
    this.shopId = shopId;
    this.partyId = partyId;
  }
}

export default StockListRequest;
