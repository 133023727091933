class LoginRequest {
  mobileNumber: string;
  password: string;

  constructor(mobileNumber: string, password: string) {
    this.mobileNumber = mobileNumber;
    this.password = password;
  }
}

export default LoginRequest;
