class BillListDescription {
  title: string;
  quantity: string;
  amount: string;

  constructor(title: string, quantity: string, amount: string) {
    this.title = title;
    this.quantity = quantity;
    this.amount = amount;
  }
}

export default BillListDescription;
