import React, { useState } from "react";
import { purchaseBillList } from "../../modals/reports/reports-response";

import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../utils/routes";
import { RUPEES_SYMBOL } from "../../utils/constants";

const PurchaseListItem: React.FC<{
  index: number;
  item: purchaseBillList;
}> = (props) => {
  const navigate = useNavigate();

  const itemClickHandler = () => {
    navigate(ROUTES.PARTY_BILL_DETAILS, {
      state: {
        data: props.item,
      },
    });
  };
  return (
    <li
      className={
        Number(props.item.totalBillAmount) === 0
          ? "list-group-item text-muted"
          : Number(props.item.paymentStatus) === 1
          ? "list-group-item text-danger"
          : "list-group-item text-success"
      }
      key={props.item.id}
      onClick={itemClickHandler}
    >
      <div className="row align-middle cursor-pointer">
        <label className="col-3">{props.item.partyName}</label>
        <label className="col-3">{props.item.billDate}</label>
        <label className="col-3">
          {Number(props.item.paymentStatus) === 1 ? "Due" : "Paid"}
        </label>
        <label className="col-3">
          {RUPEES_SYMBOL}
          {props.item.totalBillAmount}
        </label>
      </div>
    </li>
  );
};

export default PurchaseListItem;
