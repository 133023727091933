import { useTranslation } from "react-i18next";

function InputField(props) {
  const { label, type, name, handleChange, errorMessage, isValid, value } =
    props;

  const { t } = useTranslation();

  return (
    <form>
      <div className="mb-3">
        <label htmlFor={label} className="form-label">
          {t(label)}
        </label>
        <input
          type={type}
          className="form-control"
          id={label}
          aria-describedby="emailHelp"
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={t(label)}
        />
        {/* <div id="emailHelp" className="form-text">
          We'll never share your email with anyone else.
        </div> */}
        <div className="mt-2"></div>

        {errorMessage && !isValid && (
          <span className="form-text text-error">{errorMessage}</span>
        )}
      </div>
    </form>
  );
}

export default InputField;
