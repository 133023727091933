import React, { useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import { useTranslation } from "react-i18next";
import HintValue from "../../modals/billDetails/details-hint-value";
import BillListDescription from "../../modals/reports/bill-list-description";
import { expensesList } from "../../modals/reports/reports-response";
import { RUPEES_SYMBOL } from "../../utils/constants";

import DescriptionModal from "../modal/description-modal";
//import DescriptionModal from "../modal/description-modal";

const ExpensesListItem: React.FC<{
  index: number;
  item: expensesList;
}> = (props) => {
  const { t } = useTranslation();
  //console.log("Exp_item ", props.item);

  const [isShowing, setIsShowing] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<HintValue[]>([
    new HintValue(t("id"), props.item.expId),
    new HintValue(t("date"), props.item.expDate),
    new HintValue(t("type"), props.item.expType),
    new HintValue(t("payment-method"), props.item.expAmountType),
    new HintValue(t("total-amount"), RUPEES_SYMBOL + props.item.expAmount),
    new HintValue(t("description"), props.item.expOther),
  ]);

  return (
    <li
      className={
        props.index % 2
          ? "list-group-item bg-list-primary"
          : "list-group-item bg-list-secondary"
      }
      key={props.item.id}
      onClick={() => {
        setIsShowing(!isShowing);
      }}
    >
      <div className="row align-middle cursor-pointer">
        <label className="col-4">{props.item.expDate}</label>
        <label className="col-4">{props.item.expType}</label>
        <label className="col-4">
          {RUPEES_SYMBOL}
          {props.item.expAmount}
        </label>

        <DescriptionModal
          onClose={() => {
            setIsShowing(!isShowing);
          }}
          show={isShowing}
          item={selectedItem}
          title={t("expense-details")}
        ></DescriptionModal>
      </div>
    </li>
  );
};

export default ExpensesListItem;
