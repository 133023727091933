import React from "react";
import { Itemarraydetail } from "../../modals/reports/reports-response";
import { RUPEES_SYMBOL } from "../../utils/constants";

const TodoItem: React.FC<{
  index: number;
  item: Itemarraydetail;
  onRemoveItem: (id: string) => void;
  onAddHandler: (item: Itemarraydetail) => void;
}> = (props) => {
  const onRemoveHandler = (e: React.FormEvent) => {
    e.preventDefault();
    // props.onRemoveItem.bind(null, props.item.id);
    props.onRemoveItem(props.item.id);
  };

  const onAddHandler = (e: React.FormEvent) => {
    e.preventDefault();
    // props.onRemoveItem.bind(null, props.item.id);
    props.onAddHandler(props.item);
  };
  return (
    <li
      className={
        props.index % 2
          ? "list-group-item bg-list-primary"
          : "list-group-item bg-list-secondary"
      }
      key={props.item.id}
    >
      <div className="row align-middle">
        <label className="col-1">{props.index + 1}</label>
        <label className="col-5">{props.item.itemName}</label>
        <label className="col-2">
          {RUPEES_SYMBOL}
          {props.item.itemSalePrice}
        </label>
        <label className="col-2">
          {RUPEES_SYMBOL}
          {props.item.totalPrice}
        </label>

        <div className="col-6 col-md-2">
          <button className="btn btn-outline-primary" onClick={onRemoveHandler}>
            -
          </button>
          <label className="ms-2">{props.item.quantity}</label>
          <button
            className="btn btn-outline-primary ms-2"
            onClick={onAddHandler}
          >
            +
          </button>
        </div>
      </div>
    </li>
  );
};

export default TodoItem;
