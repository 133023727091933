import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";
import useForm from "../../hooks/useForm";
import LoginRequest from "../../modals/login/login-request";
import SignUpRequest from "../../modals/signUp/sign-up-request";
import { URLS } from "../../services/url";
import useHttp from "../../services/use-http";
import { signUpForm } from "../../utils/formConfig";
import { ROUTES } from "../../utils/routes";

import {
  DATE_CURRENT,
  TIME_CURRENT,
  DATE_TIME_FORMAT,
  ChangeDateTimeFormat,
} from "../../utils/date-time";
import { useTranslation } from "react-i18next";
const SignUp: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  const { renderFormInputs, isFormValid, form } = useForm(signUpForm);
  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [title, setTitle] = useState<string>("Error");

  const navigate = useNavigate();

  const signUpHandler = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isFormValid()) return;

    signupApiCall();
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "REGISTER") {
      if (data.code === 0) {
        setTitle("Success");
        setErrorMessage("Registration Successfully. Please login to continue");

        //navigate(ROUTES.LOGIN);
      } else {
        setTitle("Error");
        setErrorMessage(data.message);
      }
    }
  }, [data, params, identifier, isLoading, error]);

  const signupApiCall = () => {
    if (!isFormValid()) return;

    const request = JSON.stringify(
      new SignUpRequest(
        form.shopname.value,
        form.name.value,
        form.father.value,
        form.mobile.value,
        form.mobile.value,
        form.address.value,
        "",
        form.area.value,
        form.district.value,
        form.postalcode.value,
        form.city.value,
        form.state.value,
        form.country.value,
        form.password.value,
        form.email.value,
        form.gst.value,
        "0",
        DATE_CURRENT
      )
    );

    sendRequest(URLS.REGISTER, "POST", request, "", "REGISTER");
  };
  return (
    <div
      className="container mt-5 justify-content-center"
      onSubmit={signUpHandler}
    >
      <ErrorModal
        show={errorMessage.length === 0 ? false : true}
        onClose={() => {
          clear();
          setErrorMessage("");
          if (data && data.code === 0) {
            navigate(-1);
          }
        }}
        title={title}
        message={errorMessage}
      />
      <h3 className="text-center"> {t("sign-up")}</h3>
      {renderFormInputs()}
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-primary btn-md mt-3 mb-3"
            disabled={!isFormValid()}
            onClick={signUpHandler}
          >
            {t("sign-up")}
          </button>

          <p className="text-center">
            {t("already-have-account")}
            <NavLink to={ROUTES.LOGIN}>{t("login")}</NavLink>
          </p>
        </div>
      )}
    </div>
  );
};

export default SignUp;
