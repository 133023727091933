import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BillListDescriptionItem from "../../components/ui/bill-list-description-item";
import BillListItem from "../../components/ui/bill-list-item";
import ExpensesListItem from "../../components/ui/expenses-list-item";
import PurchaseListItem from "../../components/ui/purchase-list-item";
import BillListDescription from "../../modals/reports/bill-list-description";

import { expensesList } from "../../modals/reports/reports-response";
import { PAYMENT_MODE } from "../../utils/constants";

const ExpensesList: React.FC<{
  items: expensesList[];
}> = (props) => {
  //console.log("exp_list", props.items);

  const { t } = useTranslation();

  const totalAmount = props.items.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.expAmount)),
    0
  );

  const descriptionList: BillListDescription[] = [
    new BillListDescription(
      t("total-amount"),
      props.items.length.toString(),
      totalAmount.toString()
    ),
  ];

  return (
    <div>
      <div>
        <h6>{t("payment-description")}</h6>
        <div className="list-group">
          {descriptionList.map((item: BillListDescription, index: number) => {
            return (
              <BillListDescriptionItem
                index={index}
                item={item}
                key={item.title}
              />
            );
          })}
        </div>
      </div>
      <h6 className="mt-3">{t("expenses-list")}</h6>
      <div className="list-group">
        {props.items.map((item: any, index: number) => {
          return <ExpensesListItem index={index} item={item} key={item.id} />;
        })}
      </div>
    </div>
  );
};

export default ExpensesList;
