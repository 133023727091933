import { useLocation } from "react-router-dom";

import HintValue from "../../modals/billDetails/details-hint-value";

import useForm from "../../hooks/useForm";
import { quantityForm } from "../../utils/formConfig";

import BillDescription from "../../components/ui/bill-description";
import { RUPEES_SYMBOL } from "../../utils/constants";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const PrintLabel: React.FC<{}> = (props) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const { data } = state;

  const {
    id,
    shopId,
    partyId,
    barcodeId,
    itemName,
    itemPurchasePrice,
    itemSalesPrice,
    quantity,
    hsnCode,
  } = data;

  //console.log("print_label ", JSON.stringify(data));

  const { renderFormInputs, isFormValid, form } = useForm(quantityForm);

  const firstList: HintValue[] = [
    new HintValue(t("barcode"), barcodeId),
    new HintValue(t("item-name"), itemName),
    new HintValue(t("purchase-price"), RUPEES_SYMBOL + itemPurchasePrice),
    new HintValue(t("sale-price"), RUPEES_SYMBOL + itemSalesPrice),
    new HintValue(t("quantity"), quantity),
    new HintValue(t("hsn-code"), hsnCode),
  ];

  return (
    <div className="container-fluid mt-5 mx-1 pt-5">
      <h6>{t("item-details")}</h6>
      <ul className="list-group m-3">
        {firstList.map((item: HintValue, index: number) => {
          return (
            <BillDescription
              index={index}
              item={item}
              key={item.hint}
            ></BillDescription>
          );
        })}
      </ul>

      <div className="m-3">{renderFormInputs()}</div>

      <div className="d-flex justify-content-center m-3">
        <button
          type="button"
          className="btn btn-primary bg-success btn-md"
          disabled={!isFormValid()}
        >
          {t("print-label")}
        </button>
      </div>
    </div>
  );
};

export default PrintLabel;
