class NewPartyBillRequest {
  shopId: string;
  partyName: string;
  partyId: string;
  billNo: string;
  billDate: string;
  totalBillAmount: string;
  gstAmount: string;
  quantity: string;
  paymentStatus: string;
  billPaidDate: string;
  billRemanderDate: string;

  constructor(
    shopId: string,
    partyName: string,
    partyId: string,
    billNo: string,
    billDate: string,
    totalBillAmount: string,
    gstAmount: string,
    quantity: string,
    paymentStatus: string,
    billPaidDate: string,
    billRemanderDate: string
  ) {
    this.shopId = shopId;
    this.partyName = partyName;
    this.partyId = partyId;
    this.billNo = billNo;
    this.billDate = billDate;
    this.totalBillAmount = totalBillAmount;
    this.gstAmount = gstAmount;
    this.quantity = quantity;
    this.paymentStatus = paymentStatus;
    this.billPaidDate = billPaidDate;
    this.billRemanderDate = billRemanderDate;
  }
}

export default NewPartyBillRequest;
