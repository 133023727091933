import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";
import PartyList from "../../components/ui/party-list";
import useForm from "../../hooks/useForm";
import PartyListRequest from "../../modals/party/party-list-request";
import { PartyListResponse } from "../../modals/party/party-list-response";
import AddNewStockRequest from "../../modals/stock/add-new-stock-request";
import { AddItemResponse } from "../../modals/stock/add-new-stock-response";
import { URLS } from "../../services/url";
import useHttp from "../../services/use-http";
import { LOCALSTORAGE } from "../../utils/constants";
import { addNewStockForm } from "../../utils/formConfig";
import { ROUTES } from "../../utils/routes";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { Itemarraydetail } from "../../modals/reports/reports-response";
import { useTranslation } from "react-i18next";

const AddNewStock: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();
  const shopId = localStorage.getItem(LOCALSTORAGE.shopId)!;

  const { renderFormInputs, isFormValid, form } = useForm(addNewStockForm);

  const [errorMessage, setErrorMessage] = useState<string>(error);
  var [response, setResponse] = useState<AddItemResponse>();

  const [partyId, setPartyId] = useState<string>("");

  const navigate = useNavigate();

  const { state } = useLocation();

  //let location = useLocation();
  const { screenType } = state;

  //console.log("screenType ", screenType);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "ADD_NEW_STOCK") {
      //response = data;

      if (data.code === 0) {
        //setResponse(data.response);
        //console.log("data ", data);

        if (screenType === 1) {
          //Return to Home Screen

          // dispatch(cartActions.addItemToCart(data.response));

          // navigate(ROUTES.HOME);
          searchAddItem(data.response);
        } else if (screenType === 2) {
          //Return To Print Label Screen

          navigate(ROUTES.PRINT_LABEL, {
            state: {
              data: data.response,
            },
          });
        }

        //console.log("Response ", data);
      } else {
        setErrorMessage(data.message);
      }
    }
  }, [data, params, identifier, isLoading, error]);

  const searchAddItem = async (responseData: any) => {
    //e.preventDefault();

    //const responseData = await response!.response;
    var updatedItems: Itemarraydetail = await new Itemarraydetail(
      responseData.id.toString(),
      responseData.shopId,
      responseData.barcodeId.toString(),
      responseData.itemName,
      responseData.itemSalesPrice,
      responseData.itemPurchasePrice,
      responseData.hsnCode,
      responseData.quantity,
      "",
      (
        Number(responseData.itemSalesPrice) * Number(responseData.quantity)
      ).toString()
    );

    dispatch(cartActions.addItemToCart(updatedItems));

    navigate(ROUTES.HOME);
  };

  const submitApiCall = () => {
    if (!isFormValid()) return;

    const request = new AddNewStockRequest(
      shopId,
      partyId,
      form.itemname.value,
      form.purchaseprice.value,
      form.saleprice.value,
      "",
      form.quantity.value,
      form.hsncode.value
    );

    //console.log("Request ", JSON.stringify(request));
    sendRequest(
      URLS.ADD_NEW_STOCK,
      "POST",
      JSON.stringify(request),
      "",
      "ADD_NEW_STOCK"
    );
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    submitApiCall();
  };

  const onOptionChangeHandler = (partyId: string) => {
    setPartyId(partyId);
  };

  return (
    <div className="p-4">
      <div className="form-group ">
        <div className="d-flex justify-content-center">
          <h4>{t("add-new-stock")}</h4>
          <ErrorModal
            show={errorMessage.length === 0 ? false : true}
            onClose={clear}
            message={errorMessage}
          />
        </div>
        <PartyList onOptionChange={onOptionChangeHandler}></PartyList>

        {partyId && renderFormInputs()}

        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <div className="row">
            <button
              type="button"
              className="btn btn-primary btn-md col-12 mt-3"
              disabled={!isFormValid()}
              onClick={submitHandler}
            >
              {t("submit")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddNewStock;
