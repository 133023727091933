import { useReducer, useCallback } from "react";

type HttpState = {
  response?: any;
  loading: boolean;
  error: string;
  params?: string;
  identifier?: string;
};

const initialHttpState: HttpState = {
  response: null,
  loading: false,
  error: "",
  params: "",
  identifier: "",
};

enum ActionKindHttp {
  Send = "send",
  Response = "response",
  Error = "error",
  Clear = "clear",
}

type ActionHttp = {
  type: ActionKindHttp;
  response?: any;
  error?: string;
  params?: string;
  identifier?: string;
};

function httpReducer(state: HttpState, action: ActionHttp): HttpState {
  const { type } = action;

  switch (type) {
    case ActionKindHttp.Send:
      return {
        loading: true,
        error: "",
        params: "",
        identifier: action.identifier,
      };

    case ActionKindHttp.Response:
      return {
        ...state,
        response: action.response,
        loading: false,
        params: action.params,
      };

    case ActionKindHttp.Error:
      return {
        loading: false,
        error: action.error!,
      };

    case ActionKindHttp.Clear:
      return initialHttpState;

    default:
      return state;
  }
}

const useHttp = () => {
  const [httpState, dispatchHttp] = useReducer(httpReducer, initialHttpState);

  const clear = useCallback(() => {
    dispatchHttp({ type: ActionKindHttp.Clear });
  }, []);

  const sendRequest = useCallback(
    (
      url: string,
      method: string,
      body?: BodyInit,
      params?: string,
      identifier?: any
    ) => {
      dispatchHttp({ type: ActionKindHttp.Send, identifier: identifier });

      fetch(`${process.env.REACT_APP_API_ENDPOINT}${url}`, {
        //mode: "no-cors",
        method: method,
        body: body,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
        .then((response) => {
          //console.log("response", response);

          if (response.status === 200) {
            return response.json();
          } else {
            throw Error("Unable to get response from server");
          }
        })
        .then((responseData) => {
          //console.log("responseData", responseData);

          dispatchHttp({
            type: ActionKindHttp.Response,
            response: responseData,
            params: params,
          });
        })
        .catch((error) => {
          //console.log("error", error);
          dispatchHttp({
            type: ActionKindHttp.Error,
            error: error.message,
          });
        });
    },
    []
  );

  return {
    isLoading: httpState.loading,
    data: httpState.response,
    error: httpState.error,
    params: httpState.params,
    identifier: httpState.identifier,
    sendRequest: sendRequest,
    clear: clear,
  };
};

export default useHttp;
