import React from "react";
import { CustomerBillList } from "../../modals/reports/reports-response";

import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../utils/routes";

const BillListItem: React.FC<{
  index: number;
  item: CustomerBillList;
}> = (props) => {
  const navigate = useNavigate();

  const itemClickHandler = () => {
    navigate(ROUTES.BILL_DETAILS, {
      state: {
        data: props.item,
      },
    });
  };
  return (
    <li
      // className="list-group-item"
      className={
        props.index % 2
          ? "list-group-item bg-list-primary"
          : "list-group-item bg-list-secondary"
      }
      key={props.item.id}
      onClick={itemClickHandler}
    >
      <div
        //className="row align-middle cursor-pointer"
        className={
          Number(props.item.totalPriceWithDis) === 0
            ? "row align-middle cursor-pointer text-muted"
            : "row align-middle cursor-pointer text-dark"
        }
      >
        <label className="col-3 ">{props.item.billSerialNo}</label>
        <label className="col-5 ">
          {props.item.custBillDate + "\n" + props.item.custBillTime}
        </label>
        <label className="col-4">{props.item.totalPriceWithDis}</label>
      </div>
    </li>
  );
};

export default BillListItem;
