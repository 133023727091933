class ReportsResponse {
  code: number;
  message: string;
  customerBillList: CustomerBillList[];
  purchaseBillList: purchaseBillList[];
  expensesList: any[];

  constructor(
    code: number,
    message: string,
    customerBillList: CustomerBillList[],
    purchaseBillList: purchaseBillList[],
    expensesList: expensesList[]
  ) {
    this.code = code;
    this.message = message;
    this.customerBillList = customerBillList;
    this.purchaseBillList = purchaseBillList;
    this.expensesList = expensesList;
  }
}

export class CustomerBillList {
  id: string;
  shopId: string;
  mobileNumber: string;
  custName: string;
  otherType: string;
  paymentMode: string;
  totalQuantity: string;
  totalPriceWithoutDis: string;
  discount: string;
  gstAmount: string;
  actualAmount: string;
  totalPriceWithDis: string;
  custBillDate: string;
  custBillTime: string;
  deviceIMEI: string;
  billSerialNo: string;
  yearmonth: string;
  year: string;
  itemarraydetail: Itemarraydetail[][];

  constructor(
    id: string,
    shopId: string,
    mobileNumber: string,
    custName: string,
    otherType: string,
    paymentMode: string,
    totalQuantity: string,
    totalPriceWithoutDis: string,
    discount: string,
    gstAmount: string,
    actualAmount: string,
    totalPriceWithDis: string,
    custBillDate: string,
    custBillTime: string,
    deviceIMEI: string,
    billSerialNo: string,
    yearmonth: string,
    year: string,
    itemarraydetail: Itemarraydetail[][]
  ) {
    this.id = id;
    this.shopId = shopId;
    this.mobileNumber = mobileNumber;
    this.custName = custName;
    this.otherType = otherType;
    this.paymentMode = paymentMode;
    this.totalQuantity = totalQuantity;
    this.totalPriceWithoutDis = totalPriceWithoutDis;
    this.discount = discount;
    this.gstAmount = gstAmount;
    this.actualAmount = actualAmount;
    this.totalPriceWithDis = totalPriceWithDis;
    this.custBillDate = custBillDate;
    this.custBillTime = custBillTime;
    this.deviceIMEI = deviceIMEI;
    this.billSerialNo = billSerialNo;
    this.yearmonth = yearmonth;
    this.year = year;
    this.itemarraydetail = itemarraydetail;
  }
}

export class Itemarraydetail {
  id: string;
  shopId: string;
  itemId: string;
  itemName: string;
  itemSalePrice: string;
  purchasePrice: string;
  hsnCode: string;
  quantity: string;
  billSerialNo: string;
  totalPrice: string;

  constructor(
    id: string,
    shopId: string,
    itemId: string,
    itemName: string,
    itemSalePrice: string,
    purchasePrice: string,
    hsnCode: string,
    quantity: string,
    billSerialNo: string,
    totalPrice: string
  ) {
    this.id = id;
    this.shopId = shopId;
    this.itemId = itemId;
    this.itemName = itemName;
    this.itemSalePrice = itemSalePrice;
    this.purchasePrice = purchasePrice;
    this.hsnCode = hsnCode;
    this.quantity = quantity;
    this.billSerialNo = billSerialNo;
    this.totalPrice = totalPrice;
  }
}

export class purchaseBillList {
  id: string;
  hsbId: string;
  shopId: string;
  partyName: string;
  partyId: string;
  billNo: string;
  billDate: string;
  totalBillAmount: string;
  gstAmount: string;
  quantity: string;
  paymentStatus: string;
  billPaidDate: string;
  billRemanderDate: string;
  yearmonth: string;
  year: string;

  constructor(
    id: string,
    hsbId: string,
    shopId: string,
    partyName: string,
    partyId: string,
    billNo: string,
    billDate: string,
    totalBillAmount: string,
    gstAmount: string,
    quantity: string,
    paymentStatus: string,
    billPaidDate: string,
    billRemanderDate: string,
    yearmonth: string,
    year: string
  ) {
    this.id = id;
    this.hsbId = hsbId;
    this.shopId = shopId;
    this.partyName = partyName;
    this.partyId = partyId;
    this.billNo = billNo;
    this.billDate = billDate;
    this.totalBillAmount = totalBillAmount;
    this.gstAmount = gstAmount;
    this.quantity = quantity;
    this.paymentStatus = paymentStatus;
    this.paymentStatus = paymentStatus;
    this.billPaidDate = billPaidDate;
    this.billRemanderDate = billRemanderDate;
    this.yearmonth = yearmonth;
    this.year = year;
  }
}

export class expensesList {
  id: string;
  expId: string;
  shopId: string;
  expDate: string;
  expType: string;
  expAmountType: string;
  expOther: string;
  expAmount: string;
  regDate: string;
  yearmonth: string;
  year: string;

  constructor(
    id: string,
    expId: string,
    shopId: string,
    expDate: string,
    expType: string,
    expAmountType: string,
    expOther: string,
    expAmount: string,
    regDate: string,
    yearmonth: string,

    year: string
  ) {
    this.id = id;
    this.expId = expId;
    this.shopId = shopId;
    this.expDate = expDate;
    this.expType = expType;
    this.expAmountType = expAmountType;
    this.expOther = expOther;
    this.expAmount = expAmount;
    this.regDate = regDate;
    this.yearmonth = yearmonth;
    this.year = year;
  }
}

export default ReportsResponse;
