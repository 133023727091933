import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import BillDescription from "../../components/ui/bill-description";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";
import HintValue from "../../modals/billDetails/details-hint-value";
import DeletePartyBillRequest from "../../modals/party/delete-party-bill-request";
import UpdatePartyBillRequest from "../../modals/party/update-party-bill-request";
import { URLS } from "../../services/url";
import useHttp from "../../services/use-http";
import { RUPEES_SYMBOL } from "../../utils/constants";
import { DATE_CURRENT, DATE_TIME_FORMAT } from "../../utils/date-time";

const PartyBillDetails: React.FC<{}> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { data } = state;

  const {
    id,
    hsbId,
    shopId,
    partyName,
    partyId,
    billNo,
    billDate,
    totalBillAmount,
    gstAmount,
    quantity,
    paymentStatus,
    billPaidDate,
    billRemanderDate,
  } = data;

  const [paidDate, setPaidDate] = useState<string>(DATE_CURRENT);

  const [list, setList] = useState<HintValue[]>([
    new HintValue(t("name"), partyName),
    new HintValue(t("s-no"), billNo),
    new HintValue(t("date-time"), billDate),
    new HintValue(
      t("payment-status"),
      Number(paymentStatus) === 1 ? t("due") : t("paid")
    ),
    new HintValue(t("remainder-date"), RUPEES_SYMBOL + billRemanderDate),
    new HintValue(t("quantity"), quantity),
    new HintValue(t("gst-amount"), gstAmount),
    new HintValue(t("total-amount"), totalBillAmount),
  ]);

  var {
    isLoading,
    data: dataResponse,
    error,
    params,
    identifier,
    sendRequest,
    clear,
  } = useHttp();

  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(error);

  useEffect(() => {
    if (error) {
      setErrorStatus(true);
    } else if (!error) {
      setErrorStatus(false);
    }

    if (!isLoading && identifier === "DELETE_PARTY_BILL") {
      //console.log("deleteResponse ", dataResponse);
      if (dataResponse.code === 0) {
        setErrorMessage(dataResponse.message);

        setErrorStatus(true);
        //paymentStatus.value = "2";
        //totalBillAmount.value = "0";
      } else {
        setErrorMessage(dataResponse.message);
        setErrorStatus(true);
      }
    } else if (!isLoading && identifier === "UPDATE_PARTY_BILL") {
      //console.log("updateResponse ", dataResponse);
      if (dataResponse.code === 0) {
        setErrorMessage(dataResponse.message);

        setErrorStatus(true);
      } else {
        setErrorMessage(dataResponse.message);
        setErrorStatus(true);
      }
    }
  }, [dataResponse, params, identifier, isLoading, error]);

  const deleteHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const request = new DeletePartyBillRequest(Number(id), "0");

    sendRequest(
      URLS.DELETE_PARTY_BILL,
      "POST",
      JSON.stringify(request),
      "",
      "DELETE_PARTY_BILL"
    );
  };

  const updateHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const request = new UpdatePartyBillRequest(Number(id), "2", paidDate);

    sendRequest(
      URLS.UPDATE_PARTY_BILL,
      "POST",
      JSON.stringify(request),
      "",
      "UPDATE_PARTY_BILL"
    );
  };

  return (
    <div className="container-fluid mt-5 mx-1 pt-5">
      <ErrorModal
        show={errorStatus}
        onClose={() => {
          clear();
          setErrorStatus(false);
          if (dataResponse.code === 0) {
            navigate(-1);
          }
        }}
        message={errorMessage}
      />
      <h6>{t("party-bill-details")}</h6>

      <ul className="list-group">
        {list.map((item: HintValue, index: number) => {
          return (
            <BillDescription
              index={index}
              item={item}
              key={item.hint}
            ></BillDescription>
          );
        })}
      </ul>

      {Number(paymentStatus) === 1 ? (
        <div>
          {isLoading && <LoadingIndicator />}

          {!isLoading && (
            <div>
              <label className=" mt-4 fw-bold">{t("bill-paid-date")}</label>
              <input
                type="date"
                className="form-control mt-3"
                id="date"
                data-format={DATE_TIME_FORMAT}
                value={paidDate}
                name="dateRequired"
                //defaultValue={billDate}
                onChange={(e) => {
                  setPaidDate(e.target.value);
                }}
                max={DATE_CURRENT}
              />

              <div className="row d-flex justify-content-between mt-4 me-1 mt-3 mx-1">
                <button
                  type="button"
                  className="btn btn-primary bg-danger col-5"
                  onClick={deleteHandler}
                >
                  {t("delete")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary bg-success col-5"
                  onClick={updateHandler}
                >
                  {t("update-to-paid")}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default PartyBillDetails;
