import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";
import PartyList from "../../components/ui/party-list";

import StockListRequest from "../../modals/stock/stock-list-request";
import {
  StockListResponse,
  ItemDetails,
} from "../../modals/stock/stock-list-response";
import { URLS } from "../../services/url";
import useHttp from "../../services/use-http";
import { LOCALSTORAGE } from "../../utils/constants";
import { ROUTES } from "../../utils/routes";
import { RUPEES_SYMBOL } from "../../utils/constants";

const StockList: React.FC<{}> = (props) => {
  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();
  const shopId = localStorage.getItem(LOCALSTORAGE.shopId)!;

  const [errorMessage, setErrorMessage] = useState<string>(error);
  var [response, setResponse] = useState<StockListResponse>();

  const [partyId, setPartyId] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    if (partyId !== "") {
      const request = new StockListRequest(shopId, partyId);

      //console.log("Request ", JSON.stringify(request));
      sendRequest(
        URLS.STOCK_LIST,
        "POST",
        JSON.stringify(request),
        "",
        "STOCK_LIST"
      );
    }
  }, [partyId]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "STOCK_LIST") {
      //response = data;

      if (data.code === 0) {
        //console.log("data ", response);
        setResponse(data);

        // navigate(ROUTES.PRINT_LABEL, {
        //   state: {
        //     data: data.response,
        //   },
        // });
        //console.log("Response ", data);
      } else {
        setResponse(undefined);
        setErrorMessage(data.message);
      }
    }
  }, [data, params, identifier, isLoading, error]);

  const itemClickHandler = (item: ItemDetails) => (event: any) => {
    //e.preventDefault();
    navigate(ROUTES.PRINT_LABEL, {
      state: {
        data: item,
      },
    });
  };

  const onOptionChangeHandler = (partyId: string) => {
    setPartyId(partyId);
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-center">
        <h4>Party Wise Stock</h4>
        <ErrorModal
          show={errorMessage.length === 0 ? false : true}
          onClose={clear}
          message={errorMessage}
        />
      </div>
      <PartyList onOptionChange={onOptionChangeHandler}></PartyList>

      {isLoading && <LoadingIndicator />}
      {!isLoading && response && response.response && (
        <div className="list-group">
          {response.response.map((item: ItemDetails, index: number) => {
            //console.log("item", item);
            return (
              <li
                // className="list-group-item"
                className={
                  index % 2
                    ? "list-group-item bg-list-primary"
                    : "list-group-item bg-list-secondary"
                }
                key={item.id}
                onClick={itemClickHandler(item)}
                //onClick={itemClickHandler(item)}
              >
                <div className="row align-middle cursor-pointer">
                  <label className="col-3">{item.barcodeId}</label>
                  <label className="col-3">{item.itemName}</label>
                  <label className="col-3">{item.quantity}</label>
                  <label className="col-3">
                    {RUPEES_SYMBOL}
                    {item.itemSalesPrice}
                  </label>
                </div>
              </li>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StockList;
