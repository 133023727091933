import React from "react";
import BillListDescription from "../../modals/reports/bill-list-description";
import { RUPEES_SYMBOL } from "../../utils/constants";

const BillListDescriptionItem: React.FC<{
  index: number;
  item: BillListDescription;
}> = (props) => {
  return (
    <li
      // className="list-group-item"
      className={
        props.index % 2
          ? "list-group-item bg-list-primary"
          : "list-group-item bg-list-secondary"
      }
      key={props.item.title}
    >
      <div className="row align-middle">
        <label className="col-4 fw-bold">{props.item.title}</label>
        <label className="col-4 ">{props.item.quantity}</label>
        <label className="col-4 text-danger fw-bold">
          {RUPEES_SYMBOL}
          {props.item.amount}
        </label>
      </div>
    </li>
  );
};

export default BillListDescriptionItem;
