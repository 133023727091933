import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo_small.png";

const ComingSoon: React.FC<{}> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goBackHandler = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(-1);
  };
  return (
    <div className="container-fluid">
      {/* <img src={logo} className="rounded mx-auto d-block" alt="logo img" />
      <h1 className="text-danger">mBill</h1>
      <h1 className="text-success">Coming Soon...</h1> */}

      <div className="card m-3">
        <img src={logo} className="rounded mx-auto d-block" alt="logo img" />
        <div className="card-body text-center">
          <h1 className="card-title text-success">{t("coming-soon")}</h1>
          <button
            type="button"
            className="btn btn-success mt-3"
            onClick={goBackHandler}
          >
            {t("go-back")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
