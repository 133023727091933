import { RUPEES_SYMBOL } from "../../utils/constants";
import {
  PartyWiseBillResponse,
  BillList,
} from "../../modals/party/party-wise-bill-response";
import BillListDescription from "../../modals/reports/bill-list-description";
import BillListDescriptionItem from "../../components/ui/bill-list-description-item";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import { useTranslation } from "react-i18next";

const PartyWiseBillList: React.FC<{
  data: BillList[];
}> = (props) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  var dueAmount: number = 0;
  var paidAmount: number = 0;

  var dueQty: number = 0;
  var paidQty: number = 0;

  const totalAmount = props.data.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.totalBillAmount)),
    0
  );
  for (var data of props.data) {
    const paymentStatus = Number(data.paymentStatus);
    if (paymentStatus === 1) {
      //Due
      dueAmount += Number(data.totalBillAmount);
      dueQty += 1;
    } else if (paymentStatus === 2) {
      paidAmount += Number(data.totalBillAmount);
      paidQty += 1;
    }
  }

  const itemClickHandler = (item: BillList) => (event: any) => {
    navigate(ROUTES.PARTY_BILL_DETAILS, {
      state: {
        data: item,
      },
    });
  };

  const descriptionList: BillListDescription[] = [
    new BillListDescription(
      t("total-amount"),
      props.data.length.toString(),
      totalAmount.toString()
    ),
    new BillListDescription(t("due"), dueQty.toString(), dueAmount.toString()),
    new BillListDescription(
      t("paid"),
      paidQty.toString(),
      paidAmount.toString()
    ),
  ];

  //console.log("data ", props.data);
  return (
    <div>
      {props.data && (
        <>
          <div>
            <h6>{t("payment-description")}</h6>

            <div className="list-group">
              {descriptionList.map(
                (item: BillListDescription, index: number) => {
                  return (
                    <BillListDescriptionItem
                      index={index}
                      item={item}
                      key={item.title}
                    />
                  );
                }
              )}
            </div>
          </div>
          <h6 className="mt-3">{t("bill-list")}</h6>
          <div className="list-group ">
            {props.data.map((item: BillList, index: number) => {
              //console.log("item", item);
              return (
                <li
                  // className="list-group-item"
                  className={
                    Number(item.totalBillAmount) === 0
                      ? "list-group-item text-muted"
                      : Number(item.paymentStatus) === 1
                      ? "list-group-item text-danger"
                      : "list-group-item text-success"
                  }
                  key={item.id}
                  onClick={itemClickHandler(item)}
                >
                  <div className="row align-middle cursor-pointer">
                    <label className="col-3">{item.billNo.toString()}</label>
                    <label className="col-3">{item.billDate.toString()}</label>
                    <label className="col-3">
                      {Number(item.paymentStatus) === 1 ? "Due" : "Paid"}
                    </label>
                    <label className="col-3">
                      {RUPEES_SYMBOL}
                      {item.totalBillAmount}
                    </label>
                  </div>
                </li>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default PartyWiseBillList;
