import React from "react";
import Input from "../components/ui/Input";

import {
  requiredRule,
  minLengthRule,
  maxLengthRule,
  passwordMatchRule,
  isEmailRule,
  isMobileRule,
  isPasswordRule,
  isDiscountRule,
  isItemNameRule,
  isPriceRule,
  isGstNoRule,
  isNameRule,
  isPostalCodeRule,
} from "./inputValidationRules";
/**
 * creates and returns object representation of form field
 *
 * @param {string} label - label to show with the form input
 * @param {string} name - input name
 * @param {string} type - input type
 * @param {string} defaultValue - default value for the input
 */
function createFormFieldConfig(label, name, type, defaultValue = "") {
  return {
    renderInput: (handleChange, value, isValid, error, key) => {
      return (
        <Input
          key={key}
          name={name}
          type={type}
          label={label}
          isValid={isValid}
          value={value}
          handleChange={handleChange}
          errorMessage={error}
        />
      );
    },
    label,
    value: defaultValue,
    valid: false,
    errorMessage: "",
    touched: false,
  };
}

// object representation of signup form
export const signupForm = {
  name: {
    ...createFormFieldConfig("name", "name", "text"),
    validationRules: [
      requiredRule("name"),
      minLengthRule("name", 3),
      maxLengthRule("name", 25),
    ],
  },
  email: {
    ...createFormFieldConfig("email-id", "email", "email"),
    validationRules: [
      requiredRule("email"),
      // minLengthRule("email", 10),
      // maxLengthRule("email", 25),
      isEmailRule("email"),
    ],
  },
  password: {
    ...createFormFieldConfig("password", "password", "password"),
    validationRules: [
      requiredRule("password"),
      minLengthRule("password", 8),
      maxLengthRule("password", 20),
    ],
  },
  confirmPassword: {
    ...createFormFieldConfig("confirm-password", "confirmPassword", "password"),
    validationRules: [passwordMatchRule()],
  },
};

export const signInForm = {
  mobile: {
    ...createFormFieldConfig("mobile-number", "mobile", "number"),
    validationRules: [requiredRule("mobile"), isMobileRule("mobile")],
  },
  password: {
    ...createFormFieldConfig("password", "password", "password"),
    validationRules: [requiredRule("password"), isPasswordRule("password")],
  },
};

export const searchItemForm = {
  itemId: {
    ...createFormFieldConfig("item-id", "itemId", "number"),
    validationRules: [
      requiredRule("itemId"),
      minLengthRule("password", 4),
      maxLengthRule("password", 10),
    ],
  },
};
export const discountForm = {
  discount: {
    ...createFormFieldConfig("discount-amount", "discount", "number"),
    validationRules: [isDiscountRule("discount")],
  },
};

export const addNewStockForm = {
  itemname: {
    ...createFormFieldConfig("item-name", "itemname", "text"),
    validationRules: [isItemNameRule("itemname")],
  },
  purchaseprice: {
    ...createFormFieldConfig("purchase-price", "purchaseprice", "number"),
    validationRules: [isPriceRule("purchaseprice")],
  },
  saleprice: {
    ...createFormFieldConfig("sale-price", "saleprice", "number"),
    validationRules: [isPriceRule("saleprice")],
  },
  quantity: {
    ...createFormFieldConfig("quantity", "quantity", "number"),
    validationRules: [isPriceRule("quantity")],
  },
  hsncode: {
    ...createFormFieldConfig("hsn-code", "hsncode", "number"),
    validationRules: [],
  },
};

export const quantityForm = {
  quantity: {
    ...createFormFieldConfig("quantity", "quantity", "number"),
    validationRules: [isPriceRule("quantity")],
  },
};

export const partyBillForm = {
  billnumber: {
    ...createFormFieldConfig("bill-number", "billnumber", "number"),
    validationRules: [isPriceRule("billnumber")],
  },
  gstamount: {
    ...createFormFieldConfig("gst-amount", "gstamount", "number"),
    validationRules: [isPriceRule("billnumber")],
  },
  quantity: {
    ...createFormFieldConfig("quantity", "quantity", "number"),
    validationRules: [isPriceRule("quantity")],
  },
  amount: {
    ...createFormFieldConfig("total-bill-amount", "amount", "number"),
    validationRules: [isPriceRule("amount")],
  },
};

export const newPartyForm = {
  partyname: {
    ...createFormFieldConfig("party-name", "partyname", "text"),
    validationRules: [isItemNameRule("partyname")],
  },
  city: {
    ...createFormFieldConfig("area-city", "city", "text"),
    validationRules: [isItemNameRule("city")],
  },
  mobile: {
    ...createFormFieldConfig("mobile-number", "mobile", "number"),
    validationRules: [isMobileRule("mobile")],
  },
  email: {
    ...createFormFieldConfig("email-id", "email", "email"),
    validationRules: [isEmailRule("email")],
  },
  gst: {
    ...createFormFieldConfig("gst-number", "gst", "text"),
    validationRules: [isGstNoRule("gst")],
  },
};

export const signUpForm = {
  shopname: {
    ...createFormFieldConfig("shop-name", "shopname", "text"),
    validationRules: [isNameRule("shopname")],
  },
  name: {
    ...createFormFieldConfig("name", "name", "text"),
    validationRules: [isNameRule("name")],
  },
  father: {
    ...createFormFieldConfig("father-name", "father", "text"),
    validationRules: [isNameRule("father")],
  },
  mobile: {
    ...createFormFieldConfig("mobile-number", "mobile", "number"),
    validationRules: [isMobileRule("mobile")],
  },
  email: {
    ...createFormFieldConfig("email-id", "email", "email"),
    validationRules: [isEmailRule("email")],
  },
  address: {
    ...createFormFieldConfig("address", "address", "text"),
    validationRules: [requiredRule("address"), minLengthRule("address", 5)],
  },

  area: {
    ...createFormFieldConfig("area", "area", "text"),
    validationRules: [isNameRule("area")],
  },
  district: {
    ...createFormFieldConfig("district", "district", "text"),
    validationRules: [isNameRule("district")],
  },
  city: {
    ...createFormFieldConfig("city", "city", "text"),
    validationRules: [isNameRule("city")],
  },
  state: {
    ...createFormFieldConfig("state", "state", "text"),
    validationRules: [isNameRule("state")],
  },
  country: {
    ...createFormFieldConfig("country", "country", "text"),
    validationRules: [isNameRule("country")],
  },
  postalcode: {
    ...createFormFieldConfig("postal-code", "postalcode", "number"),
    validationRules: [isPostalCodeRule("postalcode")],
  },
  gst: {
    ...createFormFieldConfig("gst-number", "gst", "text"),
    validationRules: [isGstNoRule("gst")],
  },
  password: {
    ...createFormFieldConfig("password", "password", "password"),
    validationRules: [requiredRule("password"), isPasswordRule("password")],
  },
  confirmPassword: {
    ...createFormFieldConfig("confirm-password", "confirmPassword", "password"),
    validationRules: [passwordMatchRule()],
  },
};

export const addExpenseForm = {
  description: {
    ...createFormFieldConfig("description", "description", "text"),
    validationRules: [
      //isItemNameRule("description")
    ],
  },
  amount: {
    ...createFormFieldConfig("expense-amount", "amount", "number"),
    validationRules: [isPriceRule("amount")],
  },
};
