import React from "react";
import { Modal, Button } from "react-bootstrap";
import HintValue from "../../modals/billDetails/details-hint-value";
import { expensesList } from "../../modals/reports/reports-response";
import BillDescription from "../ui/bill-description";

const DescriptionModal: React.FC<{
  children?: React.ReactNode;
  onClose: () => void;
  show: boolean;
  item: HintValue[];
  title: string;
}> = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="list-group">
        {props.item.map((item: HintValue, index: number) => {
          return (
            <BillDescription
              index={index}
              item={item}
              key={item.hint}
            ></BillDescription>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DescriptionModal;
