class DeletePartyBillRequest {
  id: number;
  totalBillAmount: string;

  constructor(id: number, totalBillAmount: string) {
    this.id = id;
    this.totalBillAmount = totalBillAmount;
  }
}

export default DeletePartyBillRequest;
