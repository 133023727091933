import { useRouteError } from "react-router-dom";

function ErrorPageNew() {
  const error: any = useRouteError();

  return (
    <div className="m-5">
      <main id="error-content">
        <h1>An error occurred!</h1>
        <p>{error.statusText}</p>
      </main>
    </div>
  );
}

export default ErrorPageNew;
