import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ErrorModal: React.FC<{
  children?: React.ReactNode;
  onClose: () => void;
  show: boolean;
  title?: string | undefined;
  message: string;
}> = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title === undefined ? "Error" : props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Centered Modal</h4> */}
        <p>{props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
