import { useLocation, useNavigate } from "react-router-dom";

import { SearchItemResponseData } from "../../modals/home/search-item-response";

import HintValue from "../../modals/billDetails/details-hint-value";

import ItemDetails from "../../components/ui/item-details";

import BillDescription from "../../components/ui/bill-description";
import { RUPEES_SYMBOL } from "../../utils/constants";
import {
  CustomerBillList,
  Itemarraydetail,
} from "../../modals/reports/reports-response";
import { URLS } from "../../services/url";
import useHttp from "../../services/use-http";
import { useEffect, useState } from "react";
import ErrorModal from "../../components/ui/error-modal";
import DeleteBillRequest from "../../modals/billDetails/delete-bill-request";
import LoadingIndicator from "../../components/ui/loading-indicator";
import { useTranslation } from "react-i18next";

const BillDetails: React.FC<{}> = (props) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { state } = useLocation();

  const { data } = state;

  const {
    id,
    shopId,
    mobileNumber,
    custName,
    otherType,
    paymentMode,
    totalQuantity,
    totalPriceWithoutDis,
    discount,
    gstAmount,
    actualAmount,
    totalPriceWithDis,
    custBillDate,
    custBillTime,
    deviceIMEI,
    billSerialNo,
    yearmonth,
    year,
    itemarraydetail,
  } = data;

  //console.log("itemarraydetail_bill_detail ", JSON.stringify(itemarraydetail));
  const firstList: HintValue[] = [
    new HintValue(t("bill-status"), ""),
    new HintValue(t("s-no"), billSerialNo),
    new HintValue(t("date-time"), custBillDate + " " + custBillTime),
    new HintValue(t("payment-method"), paymentMode),
    new HintValue(t("name"), custName),
    new HintValue(t("mobile-number"), mobileNumber),
  ];

  const secondList: HintValue[] = [
    new HintValue(t("quantity"), totalQuantity),
    new HintValue(t("total-amount"), RUPEES_SYMBOL + totalPriceWithoutDis),
    new HintValue(t("discount"), RUPEES_SYMBOL + discount),
    new HintValue(t("cgst-sgst"), RUPEES_SYMBOL + gstAmount),
    new HintValue(
      t("total-price-include-tax"),
      RUPEES_SYMBOL + totalPriceWithDis
    ),
  ];

  var {
    isLoading,
    data: dataResponse,
    error,
    params,
    identifier,
    sendRequest,
    clear,
  } = useHttp();

  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(error);

  useEffect(() => {
    if (error) {
      setErrorStatus(true);
    } else if (!error) {
      setErrorStatus(false);
    }

    if (!isLoading && identifier === "DELETE_PARTY_BILL") {
      console.log("deleteResponse ", dataResponse);
      if (dataResponse.code === 0) {
        setErrorMessage(dataResponse.message);

        setErrorStatus(true);
        //paymentStatus.value = "2";
        //totalBillAmount.value = "0";
      } else {
        setErrorMessage(dataResponse.message);
        setErrorStatus(true);
      }
    } else if (!isLoading && identifier === "DELETE_BILL") {
      //console.log("updateResponse ", dataResponse);
      if (dataResponse.code === 0) {
        setErrorMessage(dataResponse.message);

        setErrorStatus(true);
      } else {
        setErrorMessage(dataResponse.message);
        setErrorStatus(true);
      }
    }
  }, [dataResponse, params, identifier, isLoading, error]);

  const deleteHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const request = new DeleteBillRequest(Number(id), "0");

    sendRequest(
      URLS.DELETE_BILL,
      "POST",
      JSON.stringify(request),
      "",
      "DELETE_BILL"
    );
  };

  return (
    <div className="container-fluid mt-5 mx-1 pt-5">
      <ErrorModal
        show={errorStatus}
        onClose={() => {
          clear();
          setErrorStatus(false);
          if (dataResponse.code === 0) {
            navigate(-1);
          }
        }}
        message={errorMessage}
      />
      <h6>{t("customer-details")}</h6>
      <ul className="list-group m-3">
        {firstList.map((item: HintValue, index: number) => {
          return (
            <BillDescription
              index={index}
              item={item}
              key={item.hint}
            ></BillDescription>
          );
        })}
      </ul>

      <h6>{t("item-details")}</h6>

      <ul className="list-group m-3">
        {itemarraydetail[0].map((item: Itemarraydetail, index: number) => {
          return (
            <ItemDetails index={index} item={item} key={item.id}></ItemDetails>
          );
        })}
      </ul>
      <h6>{t("payment-details")}</h6>
      <ul className="list-group m-3">
        {secondList.map((item: HintValue, index: number) => {
          return (
            <BillDescription
              index={index}
              item={item}
              key={item.hint}
            ></BillDescription>
          );
        })}
      </ul>

      {Number(totalPriceWithDis) === 0 ? null : (
        <>
          {isLoading && <LoadingIndicator />}

          {!isLoading && (
            <div className="row d-flex justify-content-between m-3">
              <button
                type="button"
                className="btn btn-primary bg-danger col-5"
                onClick={deleteHandler}
              >
                {t("delete")}
              </button>

              <button
                type="button"
                className="btn btn-primary bg-success col-5"
              >
                {t("print")}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BillDetails;
