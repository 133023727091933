class SlidingContentRequest {
  id: number;
  src: string;
  title: string;
  message: string;

  link: string;

  constructor(
    id: number,
    src: string,
    title: string,
    message: string,
    link: string
  ) {
    this.id = id;
    this.src = src;
    this.title = title;
    this.message = message;
    this.link = link;
  }
}

export default SlidingContentRequest;
