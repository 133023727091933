export enum ROUTES {
  MAIN = "/",
  LOGIN = "/login",
  SIGN_UP = "/signup",
  HOME = "/home",
  REPORTS = "/reports",
  LEDGER = "/ledger",
  ITEM_SEARCH = "/home/itemSearch",
  BILL_DETAILS = "/billDetails",
  ADD_NEW_STOCK = "/stock/AddNewStock",
  PRINT_LABEL = "/stock/PrintLabel",
  STOCK_LIST = "/stock/StockList",
  NEW_PARTY = "/party/NewParty",
  NEW_BILL = "/party/NewBill",
  PARTY_WISE_BILL = "/party/PartyWiseBill",
  PARTY_BILL_DETAILS = "/party/PartyWiseBill/Details",
  COMING_SOON = "/comingSoon",
  ADD_EXPENSE = "/expense/addExpense",
}
