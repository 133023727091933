import React, { useEffect, useState } from "react";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";
import useHttp from "../../services/use-http";
import useForm from "../../hooks/useForm";
import SearchItemRequest from "../../modals/home/search-item-request";
import { searchItemForm } from "../../utils/formConfig";
import { URLS } from "../../services/url";
import SearchItemResponse from "../../modals/home/search-item-response";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../utils/routes";
import { ItemContext } from "../../store/item-context";
import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart-slice";
import { Itemarraydetail } from "../../modals/reports/reports-response";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const ItemSearch: React.FC<{}> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { renderFormInputs, isFormValid, form } = useForm(searchItemForm);

  var [response, setResponse] = useState<SearchItemResponse>();

  const quantityArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();

  const [errorMessage, setErrorMessage] = useState<string>(error);
  const [enterQuantity, setEnterQuantity] = useState<number>(1);

  const onQuantityHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEnterQuantity(Number(e.target.value));
  };

  const searchHandler = (e: React.FormEvent) => {
    e.preventDefault();
    const request = JSON.stringify(
      new SearchItemRequest("2", form.itemId.value.trim())
    );

    sendRequest(URLS.SEARCH_ITEM, "POST", request, "", "SEARCH");
  };

  const searchAddItem = async (e: React.FormEvent) => {
    e.preventDefault();

    if (response!.response.length > 0) {
      // console.log("STATUS ", 1);

      const responseData = await response!.response[0]!;
      var updatedItems: Itemarraydetail = await new Itemarraydetail(
        responseData.id,
        responseData.shopId,
        responseData.barcodeId,
        responseData.itemName,
        responseData.itemSalesPrice,
        responseData.itemPurchasePrice,
        responseData.hsnCode,
        enterQuantity.toString(),
        "",
        (Number(responseData.itemSalesPrice) * enterQuantity).toString()
      );

      // updatedItems.id = responseData.id;
      // updatedItems.shopId = responseData.shopId;
      // updatedItems.itemId = responseData.barcodeId;
      // updatedItems.itemName = responseData.itemName;
      // updatedItems.itemSalePrice = responseData.itemSalesPrice;
      // updatedItems.purchasePrice = responseData.itemPurchasePrice;
      // updatedItems.hsnCode = responseData.hsnCode;
      // updatedItems.quantity = enterQuantity.toString();
      // updatedItems.billSerialNo = "";
      // updatedItems.totalPrice = (
      //   Number(responseData.itemSalesPrice) * enterQuantity
      // ).toString();

      // updatedItems = { ...response!.response[0]! };

      // updatedItems.quantity = quantity.toString();
      // updatedItems.totalPrice = (
      //   Number(updatedItems.itemSalesPrice) * quantity
      // ).toString();

      //      id: string,
      // shopId: string,
      // partyId: string,
      // barcodeId: string,
      // itemName: string,
      // itemPurchasePrice: string,
      // itemSalesPrice: string,
      // percentageDiscount: string,
      // quantity: string,
      // hsnCode: string,
      // regDate: string,
      // totalPrice: string

      /*  let updatedItems;
      updatedItems = { ...response!.response[0]! };

      updatedItems.quantity = quantity.toString();

      itemCtx.addItem(updatedItems); */

      dispatch(cartActions.addItemToCart(updatedItems));

      navigate(ROUTES.HOME);
    }
  };

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "SEARCH") {
      //response = data;

      if (data.code === 0) {
        setResponse(data);
        //console.log("Search ", response);
        if (data.response.length > 0) {
          setErrorMessage("");
        } else {
          setErrorMessage("Item Details not available");
        }
      } else {
        setErrorMessage(data.message);
      }
    }
  }, [data, params, identifier, isLoading, error]);
  return (
    <div className="container-fluid p-3 mt-3">
      {renderFormInputs()}

      <div className="d-flex justify-content-center">
        <ErrorModal
          show={errorMessage.length === 0 ? false : true}
          onClose={clear}
          message={errorMessage}
        />
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <button
            type="button"
            className="btn btn-primary btn-md mt-3"
            disabled={!isFormValid()}
            onClick={searchHandler}
          >
            {t("search")}
          </button>
        )}

        <form className="form-inline well ms-3 mt-3">
          <div className="form-group ">
            <select
              className="form-select"
              onChange={(e) => onQuantityHandler(e)}
            >
              {quantityArr.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
        </form>

        <button
          type="button"
          className="btn btn-primary btn-md mt-3 ms-3"
          disabled={!data}
          onClick={searchAddItem}
        >
          {t("add")}
        </button>
      </div>

      {data && response! && (
        <ul className="list-group mt-3">
          {response.response.map((item) => {
            return (
              <ul key={item.barcodeId}>
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-6">{t("barcode")}</div>
                    <div className="col-6">{item.barcodeId}</div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-6">{t("item-name")}</div>
                    <div className="col-6">{item.itemName}</div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-6">{t("purchase-price")}</div>
                    <div className="col-6">{item.itemPurchasePrice}</div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-6">{t("sale-price")}</div>
                    <div className="col-6">{item.itemSalesPrice}</div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-6">{t("discount")}</div>
                    <div className="col-6">{item.percentageDiscount}</div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-6">{t("quantity")}</div>
                    <div className="col-6">{item.quantity}</div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row">
                    <div className="col-6">{t("hsn-code")}</div>
                    <div className="col-6">{item.hsnCode}</div>
                  </div>
                </li>

                <li className="list-group-item">
                  <div className="row">
                    <div className="col-6">{t("registration-date")}</div>
                    <div className="col-6">{item.regDate}</div>
                  </div>
                </li>
              </ul>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default ItemSearch;
