import React from "react";
import HintValue from "../../modals/billDetails/details-hint-value";
import { Itemarraydetail } from "../../modals/reports/reports-response";
import { RUPEES_SYMBOL } from "../../utils/constants";

const ItemDetails: React.FC<{
  index: number;
  item: Itemarraydetail;
}> = (props) => {
  return (
    <li
      className={
        props.index % 2
          ? "list-group-item bg-list-primary"
          : "list-group-item bg-list-secondary"
      }
      key={props.item.id}
    >
      <div className="row align-middle">
        <label className="col-1">{props.index + 1}</label>
        <label className="col-3">{props.item.itemName}</label>
        <label className="col-1 ">{props.item.quantity}</label>
        <label className="col-3">
          {RUPEES_SYMBOL + props.item.itemSalePrice}
        </label>
        <label className="col-4">
          {" "}
          {RUPEES_SYMBOL}
          {Number(props.item.itemSalePrice) * Number(props.item.quantity)}
        </label>
      </div>
    </li>
  );
};

export default ItemDetails;
