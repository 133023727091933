import React from "react";
import ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import "./css/index.css";
import App from "./App";
import i18n from "./i18n/config";

import "./css/custom.scss";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./i18n/config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <I18nextProvider i18n={i18n}> */}
    <Provider store={store}>
      <App />
    </Provider>
    {/* <App /> */}
    {/* </I18nextProvider> */}
  </React.StrictMode>
);
