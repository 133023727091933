import { useEffect, useState } from "react";
import ErrorModal from "../../components/ui/error-modal";
import LoadingIndicator from "../../components/ui/loading-indicator";
import ReportItem from "../../components/ui/reports-item";
import { LOCALSTORAGE } from "../../utils/constants";

import ReportsRequest from "../../modals/reports/reports-request";
import ReportsResponse, {
  Itemarraydetail,
} from "../../modals/reports/reports-response";
import { URLS } from "../../services/url";
import useHttp from "../../services/use-http";
import { DATE_CURRENT } from "../../utils/date-time";
import BillList from "./bill-list";
import PurchaseList from "./purchase-list";
import ExpensesList from "./expenses-list";
import Summary from "./summary";
import { useTranslation } from "react-i18next";

const Reports: React.FC<{}> = (props) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<string>(DATE_CURRENT);
  const [endDate, setEndDate] = useState<string>(DATE_CURRENT);

  const [currentTab, setCurrentTab] = useState<number>(0);

  var { isLoading, data, error, params, identifier, sendRequest, clear } =
    useHttp();

  const [errorMessage, setErrorMessage] = useState<string>(error);
  var [response, setResponse] = useState<ReportsResponse>();

  const shopId = localStorage.getItem(LOCALSTORAGE.shopId)!;

  useEffect(() => {
    const request = JSON.stringify(
      new ReportsRequest(shopId, startDate, endDate)
    );

    sendRequest(URLS.REPORTS, "POST", request, "", "REPORTS");
  }, [startDate, endDate]);

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    } else if (!error) {
      setErrorMessage("");
    }

    if (!isLoading && identifier === "REPORTS") {
      //response = data;

      if (data.code === 0) {
        setResponse(data);
        //console.log("Reports ", data);
        if (data.customerBillList.length > 0) {
          setErrorMessage("");
        } else {
          setErrorMessage("Item Details not available");
        }
      } else {
        setResponse(undefined);
        setErrorMessage(data.message);
      }
    }
  }, [data, params, identifier, isLoading, error]);

  return (
    <div className="container-fluid p-3">
      <div className="row">
        <div className="input-group">
          <input
            type="date"
            className="form-control"
            id="fromDate"
            value={startDate}
            name="toDateRequired"
            //defaultValue={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
            max={DATE_CURRENT}
          />
          <div className="input-group-addon mt-1 ms-1">to</div>

          <input
            type="date"
            className="form-control ms-1"
            id="toDate"
            value={endDate}
            name="fromDateRequired"
            //defaultValue={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            max={DATE_CURRENT}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <ErrorModal
          show={errorMessage.length === 0 ? false : true}
          onClose={clear}
          message={errorMessage}
        />
        {isLoading && <LoadingIndicator />}
      </div>
      <div>
        <ul className="nav nav-tabs  nav-fill justify-content-center mt-4">
          <li
            className="nav-item cursor-pointer"
            onClick={() => {
              setCurrentTab(0);
            }}
          >
            <label
              className={currentTab === 0 ? "nav-link active" : "nav-link"}
              aria-current="page"
            >
              {t("sales")}
            </label>
          </li>
          <li
            className="nav-item cursor-pointer"
            onClick={() => {
              setCurrentTab(1);
            }}
          >
            <label
              className={currentTab === 1 ? "nav-link active" : "nav-link"}
              aria-current="page"
            >
              {t("purchase")}
            </label>
          </li>
          <li
            className="nav-item cursor-pointer"
            onClick={() => {
              setCurrentTab(2);
            }}
          >
            <label
              className={currentTab === 2 ? "nav-link active" : "nav-link"}
              aria-current="page"
            >
              {t("expenses")}
            </label>
          </li>

          <li
            className="nav-item cursor-pointer"
            onClick={() => {
              setCurrentTab(3);
            }}
          >
            <label
              className={currentTab === 3 ? "nav-link active" : "nav-link"}
              aria-current="page"
            >
              {t("summary")}
            </label>
          </li>
        </ul>
      </div>

      {data && response! && (
        <div>
          <div>
            {currentTab === 0 && (
              <ul className="list-group mt-3">
                <BillList items={response.customerBillList}></BillList>
              </ul>
            )}
          </div>

          <div>
            {currentTab === 1 && (
              <ul className="list-group mt-3">
                <PurchaseList items={response.purchaseBillList}></PurchaseList>
              </ul>
            )}
          </div>
          <div>
            {currentTab === 2 && (
              <ul className="list-group mt-3">
                <ExpensesList items={response.expensesList}></ExpensesList>
              </ul>
            )}
          </div>
          <div>
            {currentTab === 3 && (
              <ul className="list-group mt-3">
                <Summary data={response}></Summary>
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Reports;
