class NewPartyRequest {
  shopId: string;
  partyName: string;
  partyAddress: string;
  mobileNumber: string;
  email: string;
  gstNo: string;

  constructor(
    shopId: string,
    partyName: string,
    partyAddress: string,
    mobileNumber: string,
    email: string,
    gstNo: string
  ) {
    this.shopId = shopId;
    this.partyName = partyName;
    this.partyAddress = partyAddress;
    this.mobileNumber = mobileNumber;
    this.email = email;
    this.gstNo = gstNo;
  }
}

export default NewPartyRequest;
